import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Layout from 'react-splitter-layout';
import styled from 'styled-components';

import Splitter from './components/Splitter';
import EditPanel from './components/EditPanel';
import PreviewPanel from './components/PreviewPanel';
import Login from './components/Login';

import 'react-splitter-layout/lib/index.css';
import './styles/App.css';

export default class App extends Component {
    state = {
        mock: {},
        frame: {},
        frameCoordinates: null,
        gifFrames: null,
        effects: [],
        showLogin: false,
    };

    render() {
        const { mock, frame, frameCoordinates, gifFrames, effects, showLogin } = this.state;
        return (
            <Wrapper>
                <Layout
                    customClassName="main"
                    ref={ layout => this.layout = layout }
                    secondaryInitialSize={ window.innerWidth <= 1024 ? 500 : 700 }
                >
                    <EditPanel
                        onChange={ this.handleMockChange }
                        onEffectsChange={ this.handleEffectsChange }
                    />
                    <PreviewPanel
                        mock={ mock }
                        frames={ frame }
                        frameCoordinates={ frameCoordinates }
                        gifFrames={ gifFrames }
                        effects={ effects }
                        onLoginClick={ this.handleLoginClick }
                    />
                </Layout>
                { showLogin && this.renderLogin() }
            </Wrapper>
        );
    }

    componentDidMount() {
        ReactDOM.render(React.createElement(Splitter), this.layout.splitter);
    }

    renderLogin() {
        return (
            <Login
                onLoginSuccess={ this.handleLoginSuccess }
                onCancel={ this.handleLoginCancel }
            />
        );
    }

    handleLoginClick = () => {
        this.setState({ showLogin: true });
    }

    handleLoginCancel = () => {
        this.setState({ showLogin: false });
    }

    handleLoginSuccess = () => {
        this.setState({ showLogin: false });
    }

    handleMockChange = (mock, frame, frameCoordinates, gifFrames) => {
        this.setState({ mock, frame, frameCoordinates, gifFrames });
    }

    handleEffectsChange = (effects) => {
        this.setState({ effects });
    }
}

const Wrapper = styled.div`
`;