import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import cx from 'classnames';
import { Rnd as Frame } from 'react-rnd';
import { Tabs, Tab } from '../lib/simpletabs';
import Button from './Button';
import WebpageTaker from './WebpageTaker';
import { debounce } from '../utils/debounce';
import { dataURIToArrayBuffer, applyEffects, effectsChanged } from '../utils/image';
import mixins from '../styles/mixins';
import colors from '../styles/colors';
import '../lib/gifuct-js.min';

import 'rc-slider/assets/index.css';
import '../styles/EditArea.css';

export default class EditArea extends Component {
    static defaultProps = {
        mock: {},
        effects: [],
        onFrameChange: () => Promise.resolve(),
    };

    state = {
        data: {
            0: {
                image: null,
                frame: null,
                scale: 0,
                isGIF: false,
                gifFrames: [],
                promptURL: false,
            },
        },
        lastUpdated: Date.now(),
        lockAspectRatio: false,
        tabActive: 0,
    };

    componentWillReceiveProps(nextProps) {
        const { data, tabActive } = this.state;
        const { effects } = this.props;
        const { mock } = this.props;
        const newEffects = nextProps.effects;
        const changed = effectsChanged(effects, newEffects);
        if (changed) {
            this.getFramedImage();
        }
        const oldFrames = Array.isArray(mock.perspective) ? mock.perspective : [mock.perspective];
        const newFrames = Array.isArray(nextProps.mock.perspective) ? nextProps.mock.perspective : [nextProps.mock.perspective];
        const framesChanged = oldFrames.length !== newFrames.length;

        const oldRatios = Array.isArray(mock.ratio) ? mock.ratio : [mock.ratio];
        const newRatios = Array.isArray(nextProps.mock.ratio) ? nextProps.mock.ratio : [nextProps.mock.ratio];
        const ratiosChanged = oldRatios.length !== newRatios.length;

        const mockChanged = nextProps.mock.fullSize !== this.props.mock.fullSize;
        const newData = {
            0: {
                image: data[0] && data[0].image || null,
                frame: mockChanged ? null : (data[0] && data[0].frame || null),
                scale: mockChanged ? 0 : (data[0] && data[0].scale || 0),
                isGIF: data[0] && data[0].isGIF || false,
                gifFrames: mockChanged ? [] : (data[0] && data[0].gifFrames || []),
                promptURL: data[0] && data[0].promptURL || false,
            }
        };
        if (Array.isArray(nextProps.mock.perspective)) {
            nextProps.mock.perspective.forEach((p, idx) => {
                newData[idx] = {
                    image: data[idx] && data[idx].image || null,
                    frame: mockChanged ? null : (data[idx] && data[idx].frame || null),
                    scale: mockChanged ? 0 : (data[idx] && data[idx].scale || 0),
                    isGIF: data[idx] && data[idx].isGIF || false,
                    gifFrames: mockChanged ? [] : (data[idx] && data[idx].gifFrames || []),
                    promptURL: data[idx] && data[idx].promptURL || false,
                }
            });
        }
        this.setState({
            data: newData,
            tabActive: Object.keys(newData).length > tabActive ? tabActive : 0,
        });
    }

    render() {
        const { tabActive, data } = this.state;
        const { mock } = this.props;
        let tabs;
        if (Array.isArray(mock.perspective)) {
            tabs = mock.perspective.map((p, idx) => {
                return {
                    idx,
                    perspective: p,
                    ratio: mock.ratio[idx],
                };
            });
        } else {
            tabs = [{
                idx: 0,
                perspective: mock.perspective,
                ratio: mock.ratio,
            }];
        }

        return (
            <Wrapper
                isEmpty={ !data[tabActive].image }
                isPrompt={ data[tabActive].promptURL }
            >
                {
                    !data[tabActive].promptURL &&
                    <EditActions>
                        <Button
                            text="Upload"
                            icon="upload"
                            action={ this.handleUploadClick }
                        />
                        <Button
                            text="Webpage"
                            icon="link"
                            action={ this.handleWebpageClick }
                        />
                        <Button
                            text="Remove"
                            icon="remove"
                            disabled={ !data[tabActive].image }
                            action={ this.handleRemoveClick }
                        />
                    </EditActions>
                }
                <Tabs
                    tabActive={ tabActive + 1 }
                    onBeforeChange={ this.handleTabBeforeChange }
                >
                    {
                        tabs.map((tab, idx) => {
                            return (
                                <Tab
                                    key={ tab.idx }
                                    title={ `image ${idx + 1}` }
                                >
                                    { !data[tabActive].promptURL && this.renderDropzone(idx) }
                                    { data[tabActive].image && !data[tabActive].promptURL && this.renderImage(idx) }
                                    { data[tabActive].promptURL && this.renderPromptURL(idx) }
                                </Tab>
                            )
                        })
                    }
                </Tabs>
            </Wrapper>
        );
    }

    renderImage() {
        const { mock } = this.props;
        const { data, lastUpdated, lockAspectRatio, tabActive } = this.state;
        const { image, frame } = data[tabActive];
        const handleStyles = {
            width: '10px',
            height: '10px',
            background: 'red',
        };
        const scaledW = (this.imageElement && this.imageElement.width) || 0;
        const scaledH = (this.imageElement && this.imageElement.height) || 0;

        let defaultFrameHeight = scaledH;
        let defaultFrameWidth = defaultFrameHeight * (Array.isArray(mock.ratio) ? mock.ratio[tabActive] : mock.ratio);
        let defaultX = 0;

        if (defaultFrameWidth > scaledW) {
            defaultFrameHeight = scaledH * (scaledW / defaultFrameWidth);
            defaultFrameWidth = scaledW;
        } else {
            defaultX = (scaledW - defaultFrameWidth) / 2;
        }

        return (
            <ImageWrapper ref={ this.handleWrapperLoaded }>
                <ImageInner>
                    {
                        this.imageWrapper &&
                        <UploadedImage
                            key={ lastUpdated }
                            ref={ this.handleImageLoaded }
                            src={ image.src }
                            height={ this.imageWrapper.clientHeight }
                        />
                    }
                    {
                        this.imageElement &&
                        <Effects
                            ref={ effects => this.effectsCanvas = effects }
                            width={ this.imageElement.width }
                            height={ this.imageElement.height }
                        />
                    }
                    {
                        this.imageElement && this.imageWrapper &&
                        <Frame
                            key={ mock.fullSize }
                            ref={ this.handleFrameInit }
                            default={{
                                x: frame ? frame.x : defaultX,
                                y: frame ? frame.y : 0,
                                width: frame ? frame.w : defaultFrameWidth,
                                height: frame ? frame.h : defaultFrameHeight,
                            }}
                            style={{
                                left: 0,
                                top: 0,
                                border: '1px solid red',
                                position: 'absolute',
                            }}
                            bounds="parent"
                            resizeHandlerStyles={{
                                topLeft: handleStyles,
                                topRight: handleStyles,
                                bottomLeft: handleStyles,
                                bottomRight: handleStyles,
                            }}
                            lockAspectRatio={ lockAspectRatio }
                            onResizeStart={ this.handleFrameResizeStarted }
                            onResize={ this.handleFrameChanged }
                            onDrag={ this.handleFrameChanged }
                        />
                    }
                </ImageInner>
            </ImageWrapper>
        );
    }

    renderPromptURL() {
        return (
            <WebpageTaker
                onComplete={ this.handleWebpageComplete }
                onCancel={ this.handleWebpageClose }
            />
        );
    }

    renderDropzone() {
        const { data, tabActive } = this.state;
        const { image } = data[tabActive];
        return (
            <DropzoneWrapper invisible={ !!image }>
                <Dropzone ref={element => this.dropzone = element} onDrop={ this.handleFileSelected }>
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps({
                            className: cx('dropzone', {'invisible': !!image}),
                        })}>
                            <input {...getInputProps()} />
                            <Empty>Upload Image</Empty>
                        </div>
                    )}
                </Dropzone>
                {/*
                <Dropzone
                    ref={ dropzone => this.dropzone = dropzone }
                    className={ cx('dropzone', {'invisible': !!image}) }
                    accept="image/*"
                    multiple={ false }
                    onDrop={ this.handleFileSelected }
                >
                    <Empty>Upload Image</Empty>
                </Dropzone>
                */}
            </DropzoneWrapper>
        );
    }

    handleFrameInit = (frame) => {
        if (frame) {
            this.frame = frame;
            this.handleFrameChanged();
        }
    }

    handleWrapperLoaded = (imageWrapper) => {
        if (imageWrapper) {
            this.imageWrapper = imageWrapper;
            this.forceUpdate();
        }

    }

    handleImageLoaded = (imageElement) => {
        if (imageElement) {
            this.imageElement = imageElement;
            this.forceUpdate();
        }
    }

    handleFileSelected = (files) => {
        const reader = new FileReader();
        reader.onloadstart = () => {
        };
        reader.onabort = () => {
        };
        reader.onerror = () => {
        };
        reader.onload = (event) => {
            const { data, tabActive } = this.state;
            const image = new Image();
            image.src = event.target.result;
            image.onload = () => {
                this.imageWrapper = null;
                this.imageElement = null;
                this.setState(prevState => {
                    const { data, tabActive } = prevState;
                    let gifFrames = [];
                    const isGIF = (event.target.result || '').indexOf('data:image/gif;') === 0;
                    if (isGIF) {
                        const arrayBuffer = dataURIToArrayBuffer(event.target.result);
                        if (arrayBuffer) {
                            const byteArray = new Uint8Array(arrayBuffer);
                            const gif = new window.UNGIF(byteArray);
                            gifFrames = gif.decompressFrames(true);
                        }
                    }
                    return {
                        data: {
                            ...data,
                            [tabActive]: {
                                ...data[tabActive],
                                isGIF,
                                gifFrames,
                                image,
                            },
                        },
                        lastUpdated: Date.now(),
                    }
                });
            }
        }
        reader.readAsDataURL(files[0]);
    }

    handleUploadClick = () => {
        this.dropzone.open();
    }

    handleWebpageClick = () => {
        const { data, tabActive } = this.state;
        this.setState({
            data: {
                ...data,
                [tabActive]: {
                    ...data[tabActive],
                    promptURL: true,
                },
            }
        });
    }

    handleWebpageClose = () => {
        const { data, tabActive } = this.state;
        this.setState({
            data: {
                ...data,
                [tabActive]: {
                    ...data[tabActive],
                    promptURL: false,
                },
            }
        });
    }

    handleWebpageComplete = (webpageObject) => {
        const { data, tabActive } = this.state;
        this.setState({
            data: {
                ...data,
                [tabActive]: {
                    ...data[tabActive],
                    promptURL: false,
                    ...webpageObject,
                },
            }
        });
    }

    handleRemoveClick = () => {
        const { data, tabActive } = this.state;
        this.setState({
            data: {
                ...data,
                [tabActive]: {
                    image: null,
                },
            }
        }, () => this.props.onFrameChange(tabActive));
    }

    handleFrameResizeStarted = (event, direction, ref) => {
        this.setState({
            lockAspectRatio: ['topLeft', 'topRight', 'bottomLeft', 'bottomRight'].indexOf(direction) >= 0,
        });
    }

    handleFrameChanged = () => {
        const { data, tabActive } = this.state;
        const { image } = data[tabActive];
        const x = this.frame.draggable.state.x;
        const y = this.frame.draggable.state.y;
        const w = this.frame.resizable.state.width;
        const h = this.frame.resizable.state.height;
        const scale = image.width / this.imageElement.width;
        this.setState({
            data: {
                ...data,
                [tabActive]: {
                    ...data[tabActive],
                    frame: { x, y, w, h },
                    scale,
                }
            }
        }, this.getFramedImage);
    }

    getFramedImage = debounce(() => {
        const { effects } = this.props;
        const { data, tabActive } = this.state;
        const { scale, frame, image } = data[tabActive];

        if (image && image.width && frame) {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);

            const rx = frame.x * scale;
            const ry = frame.y * scale;
            const rw = frame.w * scale;
            const rh = frame.h * scale;

            if (rw + rh > 0) {
                const framedImageData = ctx.getImageData(rx, ry, rw, rh);

                canvas.width = rw;
                canvas.height = rh;
                ctx.putImageData(framedImageData, 0, 0);

                if (effects) {
                    if (this.effectsCanvas) {
                        if (Array.isArray(effects) && effects.length > 0) {
                            applyEffects(effects, canvas, this.effectsCanvas, frame);
                        } else {
                            this.effectsCanvas.getContext('2d').clearRect(0, 0, this.effectsCanvas.width, this.effectsCanvas.height);
                        }
                    }
                    applyEffects(effects, canvas, canvas, {x: 0, y: 0, w: rw, h: rh});
                }

                const framedImage = new Image();
                framedImage.src = canvas.toDataURL();
                framedImage.onload = () => {
                    const { isGIF, gifFrames } = data[tabActive];
                    this.props.onFrameChange(tabActive, framedImage, { x: rx, y: ry, w: rw, h: rh }, isGIF ? gifFrames : null);
                };
            }
        }
    }, 500);

    handleTabBeforeChange = (tabActive) => {
        this.setState({ tabActive: tabActive - 1 });
    }
}

const Wrapper = styled.div`
    ${mixins.flexCol};
    width: 100%;
    // ${props => props.isPrompt && ('height:' + (~~(3 * window.innerHeight / 4) + 'px'))};
    position: relative;
    flex: 1 1 auto;
    font-size: 2rem;
    color: ${colors.darkGray};

    .tabs {
        flex: 1 1 auto;
        ${mixins.flexCol};

        .tab-panel {
            padding: 0;
            flex: 1 1 auto;
            ${mixins.flexCol};

            &>div {
                flex: 1 1 auto;
                ${mixins.flexCol};

                .dropzone {
                    ${mixins.flexCenter};

                    div {
                        flex: 0 0 auto;
                    }
                }
            }
        }
    }

    .tabs-navigation {
        max-height: 2rem;
        background-color: ${colors.darkGray};
        border: 1px solid ${colors.darkGray};
        margin-bottom: 0.5rem;
    }

    .tabs-menu {
        display: table;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .tabs-menu-item {
        float: left;
        padding: 0.5rem 1rem;
        text-transform: capitalize;
    }

    .tabs-menu-item a {
        display: block;
        font-size: 1rem;
        color: #a9a9a9;
    }

    .tabs-menu-item:not(.is-active) a:hover {
        color: ${colors.gray};
        cursor: pointer;
    }

    .tabs-menu-item.is-active a {
        color: ${colors.orange};
    }

    .tabs-panel {
        display: none;
    }

    .tabs-panel.is-active {
        display: block;
    }
`;

const EditActions = styled.div`
    ${mixins.flexRow};
    padding-bottom: 0.5rem;
    width: 100%;
    flex: 0 0 auto;

    button {
        margin-right: 0.5rem;
    }
`;

const DropzoneWrapper = styled.div`
    ${mixins.flexCol};
    flex: 1 1 auto;
    ${props => props.invisible && 'display: none'};
`;

const Empty = styled.div`
    flex: 0 0 auto;
`;

const ImageWrapper = styled.div`
    ${mixins.flexCol};
    ${mixins.flexCenter};
    flex: 1 1 auto;
`;

const ImageInner = styled.div`
    ${mixins.flexRow};
    flex: 0 0 auto;
    position: relative;
}
`;

const UploadedImage = styled.img`
    ${props => props.height ? `height: ${props.height}px` : 'height: 0'};
`;

const Effects = styled.canvas`
    position: absolute;
    left: 0;
    right: 0;
`;
