import React, { Component } from 'react';
import ReactDOM from 'react-dom';
// import AdSense from 'react-adsense';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import DB from '../services/DB';
import Panel from './Panel';
import Button from './Button';
import { debounce } from '../utils/debounce';
import { getPerspectiveImage, getGIFFrameImage, applyEffects, effectsChanged } from '../utils/image';
import { saveAs } from 'file-saver';
import '../lib/canvas-toBlob';
import GIF from '../lib/gif';
import { AnimatedEncoder } from '../lib/AnimatedEncoder';
import '../lib/whammy';
import tangldImage from '../img/tangld.png';

import mixins from '../styles/mixins';
import colors from '../styles/colors';

const PreviewPanel = inject('stores')(observer(class PreviewPanel extends Component {
    static defaultProps = {
        mock: {},
        frames: {
            0: {},
        },
        frameCoordinates: {
            0: null,
        },
        gifFrames: {
            0: null,
        },
        effects: [],

        onLoginClick: () => Promise.resolve(),
    }

    state = {
        loading: false,
        previewImage: {},
        processingFrame: 0,
        downloadType: 'gif',
    }

    render() {
        const { mock, gifFrames, stores } = this.props;
        const { credits } = mock || {};
        let { link, title } = credits || {};
        const { previewImage, loading, processingFrame, downloadType } = this.state;
        const { width = 0, height = 0 } = previewImage || {};

        let processingText = 'Processing image...';
        const hasGif = gifFrames && Object.keys(gifFrames).filter(key => gifFrames[key] && gifFrames[key].length).length > 0;
        const lastFrame = gifFrames ? Math.max(...Object.keys(gifFrames).map(key => gifFrames[key] ? gifFrames[key].length : 0)) : 0;

        if (processingFrame) {
            if (processingFrame <= lastFrame) {
                processingText = `Processing image... (frame ${processingFrame} / ${lastFrame})`;
            } else {
                processingText = `Processing image... (encoding file)`;
            }
        }

        return (
            <Panel>
                <Wrapper>
                    <Actions>
                        <Downloads>
                            {
                                hasGif &&
                                <DownloadType>
                                    <DownloadTypeSelect>
                                        <span>Download as</span>
                                        <select value={ downloadType } onChange={ this.handleChangeDownloadType }>
                                            <option value="gif">GIF</option>
                                            <option value="apng">aPNG</option>
                                            <option value="video">Video (webm)</option>
                                        </select>
                                    </DownloadTypeSelect>
                                    <DownloadTypeHint>
                                        <span>
                                            { downloadType === 'gif' && 'wide support, fast encoding, medium quality, large file size' }
                                            { downloadType === 'apng' && 'medium support, slow encoding, good quality, small file size' }
                                            { downloadType === 'video' && 'medium support, fast encoding, good quality, small file size' }
                                        </span>
                                    </DownloadTypeHint>
                                </DownloadType>
                            }
                            <Button
                                icon="download"
                                text="100%"
                                title="Download full size"
                                hint={ `${width} x ${height} px` }
                                action={ () => this.handleDownload(1) }
                            />
                            <Button
                                icon="download"
                                text="50%"
                                title="Download half size"
                                hint={ `${~~(width / 2)} x ${~~(height / 2)} px` }
                                action={ () => this.handleDownload(0.5) }
                            />
                            <Button
                                icon="download"
                                text="25%"
                                title="Download quarter size"
                                hint={ `${~~(width / 4)} x ${~~(height / 4)} px` }
                                action={ () => this.handleDownload(0.25) }
                            />
                        </Downloads>
                        {
                            !stores.auth.isAuthenticated ?
                            <Button
                                icon="sign-in"
                                text="Login"
                                title="Login"
                                action={ this.handleLoginClick }
                            /> :
                            <Button
                                icon="sign-out"
                                text="Logout"
                                title="Logout"
                                action={ this.handleLogoutClick }
                            />
                        }
                    </Actions>
                    <Preview>
                        <PreviewImage
                            ref={ element => this.previewElement = element }
                            src={ previewImage.src }
                        />
                        <Overlay ref={ overlay => this.overlay = overlay }/>
                        { loading && <Loading>{ processingText }</Loading> }
                        <Hint>This is preview. Download for better quality.</Hint>
                    </Preview>
                    { link && <Credits>Credits: <a href={ link }>{ title || link }</a></Credits> }
                    <AdsContainer ref={ element => this.adsContainer = element }/>
                </Wrapper>
            </Panel>
        );
    }

    componentDidMount() {
        const { mock, frames } = this.props;
        if (mock.fullSize) {
            this.setState({ loading: true }, () => this.loadPreview(mock, frames));
        }
        if (this.adsContainer) {
            /*
            setTimeout(() => {
                ReactDOM.render(<AdSense.Google
                    className="adsbygoogle"
                    client='ca-pub-0742327987951100'
                    slot='6729908271'
                    data-ad-format="auto"
                />, this.adsContainer);
            }, 1000);
            */
            setTimeout(() => {
                ReactDOM.render(
                    (
                        <>
                            <a href="https://tangld.in" target="_blank" rel="noopener noreferrer">
                                <img src={tangldImage} />
                            </a>
                        </>
                    ), this.adsContainer
                );
            }, 2500);
        }
    }

    componentWillReceiveProps(nextProps) {
        const { mock, frames, effects } = nextProps;
        let framesChaged = false;
        const framesCount = Object.keys(frames).length;
        const mockChanged = mock.fullSize !== this.props.mock.fullSize;
        for (let i = 0; i < framesCount && !framesChaged; i++) {
            const newFrameIsEmpty = !(frames[i] && frames[i].src);
            const oldFrameIsEmpty = !(this.props.frames[i] && this.props.frames[i].src);
            framesChaged = (
                (!newFrameIsEmpty && !oldFrameIsEmpty && (frames[i].src !== this.props.frames[i].src)) ||
                (newFrameIsEmpty && !oldFrameIsEmpty) || (!newFrameIsEmpty && oldFrameIsEmpty)
            );
        }
        if (mock.fullSize && (mockChanged || framesChaged || effectsChanged(this.props.effects, nextProps.effects))) {
            this.setState({ loading: true }, () => this.loadPreview(mock, frames));
        }
    }

    handleLoginClick = () => {
        this.props.onLoginClick();
    }

    handleLogoutClick = () => {
        DB.auth.signOut();
    }

    handleChangeDownloadType = (event) => {
        this.setState({ downloadType: event.target.value });
    }

    loadPreview = debounce((mock, frames) => {
        const image = new Image();
        image.src = mock.fullSize;
        image.onload = () => {
            this.setState({
                previewImage: image,
            }, () => {
                this.overlay.width = this.previewElement.width;
                this.overlay.height = this.previewElement.height;
                Object.keys(frames).forEach(key => {
                    const frame = frames[key];
                    if (!frame || !frame.width) {
                        this.setState({ loading: false });
                    } else {
                        const transformedImageData = getPerspectiveImage(key, frame, mock, this.previewElement.width, this.previewElement.height);
                        const ctx = this.overlay.getContext('2d');
                        ctx.drawImage(transformedImageData, 0, 0);

                        /* replace perspectiveImage with transformedImageData
                        const { effects } = this.props;
                        const effectsObject = {};
                        effects.forEach(effect => {
                            effectsObject[effect.name] = {
                                value: effect.value,
                            };
                        });

                        if (effectsObject.duplicate && effectsObject.duplicate.value.count) {
                            const r = 70;
                            const { angle, radius, count } = effectsObject.duplicate.value
                            for (let i = 0; i < count; i++) {
                                const x = (i + 1) * r * radius * Math.cos(angle);
                                const y = (i + 1) * r * radius * Math.sin(angle);
                                ctx.drawImage(perspectiveImage, x, y);
                                ctx.drawImage(perspectiveImage, x, y - 1);
                                ctx.drawImage(perspectiveImage, x, y - 2);
                            }
                        }
                        */
                        this.setState({
                            loading: false,
                        });
                    }
                });
            });
        }
    }, 500);

    handleDownload = (scale) => {
        this.setState({
            loading: true,
        }, () => {
            const { mock, frames, frameCoordinates, gifFrames } = this.props;
            const image = new Image();
            image.src = mock.fullSize;
            image.onload = () => {
                const scaledWidth = image.width * scale;
                const scaledHeight = image.height * scale;
                let frameImageData = {};
                const resultCanvas = document.createElement('canvas');
                const resultCtx = resultCanvas.getContext('2d');
                resultCanvas.width = scaledWidth;
                resultCanvas.height = scaledHeight;
                const framesArray = Object.keys(frames).map(key => frames[key]);
                const hasGif = Object.keys(gifFrames).filter(key => gifFrames[key] && gifFrames[key].length).length > 0;
                let processImageFrame;
                if (hasGif) {
                    const { downloadType } = this.state;
                    const lastFrame = gifFrames ? Math.max(...Object.keys(gifFrames).map(key => gifFrames[key] ? gifFrames[key].length : 0)) : 0;
                    let encoder;
                    if (downloadType === 'gif') {
                        encoder = new GIF({
                            workers: 4,
                            quality: 10,
                            width: scaledWidth,
                            height: scaledHeight,
                            transparent: mock.transparent ? 'rgba(0,0,0,0)' : null,
                            workerScript: '/js/gif.worker.js',
                        });
                    } else if (downloadType === 'apng') {
                        encoder = new AnimatedEncoder({
                            format: 'png',
                            loops: 0,
                            onEncoded: () => {
                                saveAs(new Blob([encoder.outputOctetStream], {'type': `image/${encoder.format}`}), 'pictofon.png');
                                this.setState({
                                    loading: false,
                                    processingFrame: 0,
                                });
                            }
                        }, 1);
                    } else if (downloadType === 'video') {
                        encoder = new window.Whammy.Video();
                    }
                    const f = (i) => {
                        if (i < lastFrame) {
                            resultCtx.drawImage(image, 0, 0, scaledWidth, scaledHeight);
                            this.setState({ processingFrame: i + 1 }, () => {
                                setTimeout(() => {
                                    const minDelay = Math.min(...Object.keys(gifFrames).map(key => {
                                        return gifFrames[key] && gifFrames[key][i] ? gifFrames[key][i].delay : 10000;
                                    }));
                                    Object.keys(frames).forEach(key => {
                                        const frame = frames[key];
                                        if (frame && frame.width) {
                                            if (gifFrames[key] && gifFrames[key].length > 0) {
                                                if (typeof frameImageData[key] === 'undefined') {
                                                    frameImageData[key] = null;
                                                }
                                                const realFrameX = frameCoordinates[key].x;
                                                const realFrameY = frameCoordinates[key].y;
                                                const realFrameW = frameCoordinates[key].w;
                                                const realFrameH = frameCoordinates[key].h;
                                                const { effects } = this.props;
                                                
                                                const result = getGIFFrameImage(
                                                    key, frameImageData[key],
                                                    gifFrames[key][i % gifFrames[key].length],
                                                    realFrameX, realFrameY, realFrameW, realFrameH
                                                );
                                                const frameData = result.frameData;
                                                frameImageData[key] = result.frameImageData;
                                                const canvas = document.createElement('canvas');
                                                canvas.width = realFrameW;
                                                canvas.height = realFrameH;
                                                canvas.getContext('2d').putImageData(frameData, 0, 0);
                                                applyEffects(effects, canvas, canvas, {x: 0, y: 0, w: realFrameW, h: realFrameH});
                                                const transformedImageData = getPerspectiveImage(key, canvas, mock, scaledWidth, scaledHeight);
                                                resultCtx.drawImage(transformedImageData, 0, 0);
                                            } else {
                                                const transformedImageData = getPerspectiveImage(key, frame, mock, scaledWidth, scaledHeight);
                                                resultCtx.drawImage(transformedImageData, 0, 0);
                                            }
                                        }
                                    });
                                    if (downloadType === 'gif') {
                                        encoder.addFrame(resultCtx, {
                                            copy: true,
                                            delay: minDelay,
                                        });
                                        f(i + 1);
                                    } else if (downloadType === 'apng') {
                                        const frameImage = new Image();
                                        frameImage.onload = () => {
                                            encoder.addFrame({
                                                image: frameImage,
                                                delay: minDelay,
                                            });
                                            f(i + 1);
                                        };
                                        frameImage.src = resultCanvas.toDataURL();
                                    } else if (downloadType === 'video') {
                                        encoder.add(resultCanvas, minDelay);
                                        f(i + 1);
                                    }
                                }, 5);
                            });
                        } else {
                            this.setState({ processingFrame: lastFrame + 1 });
                            if (downloadType === 'gif') {
                                encoder.render();
                                encoder.on('finished', blob => {
                                    saveAs(blob, 'pictofon.gif');
                                    this.setState({
                                        loading: false,
                                        processingFrame: 0,
                                    });
                                });
                            } else if (downloadType === 'apng') {
                                encoder.saveAnimatedFile();
                            } else if (downloadType === 'video') {
                                encoder.compile(false, (output) => {
                                    saveAs(output, 'pictofon.webm');
                                    this.setState({
                                        loading: false,
                                        processingFrame: 0,
                                    });
                                });
                            }
                        }
                    }
                    f(0);
                } else {
                    resultCtx.drawImage(image, 0, 0, scaledWidth, scaledHeight);
                    Object.keys(frames).forEach((key, idx) => {
                        const frame = frames[key];
                        if (frame && frame.width) {
                            const transformedImageData = getPerspectiveImage(key, frame, mock, scaledWidth, scaledHeight);
                            resultCtx.drawImage(transformedImageData, 0, 0);
                        }
                    });
                    resultCanvas.toBlob(blob => {
                        this.setState({ loading: false });
                        saveAs(blob, 'pictofon.png');
                    });
                }
            }
        });
    }
}));

export default PreviewPanel;

const Wrapper = styled.div`
    ${mixins.flexCol};
    padding: 1rem;
`;

const Actions = styled.div`
    ${mixins.flexRow};
    justify-content: space-between;
`;

const Downloads = styled.div`
    ${mixins.flexRow};
    flex-wrap: wrap;
    user-select: none;
    button {
        margin-right: 0.5rem;
    }
`;

const DownloadType = styled.div`
    ${mixins.flexCol};
    justify-content: flex-end;
    margin-right: 1rem;
`;

const DownloadTypeSelect = styled.div`
    ${mixins.flexRow};
    span {
        margin-right: 0.45rem;
    }
`;

const DownloadTypeHint = styled.div`
    font-size: 0.75rem;
    align-self: flex-end;
    padding-top: 0.1rem;
    max-width: 13rem;
`

const Preview = styled.div`
    ${mixins.flexCol};
    ${mixins.flexCenter};
    margin-top: 0.5rem;
    position: relative;
`;

const PreviewImage = styled.img`
    max-width: 100%;
    min-height: 200px;
    max-height: ${Math.round(2 * window.innerHeight / 3)}px;
`;

const Credits = styled.div`
    padding-top: 1rem;
    width: 100%;
    text-align: right;
    font-size: 0.75rem;
`;

const Hint = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    background-color: ${colors.darkRed};
    opacity: 0.8;
    padding: 0.2rem 0;
    color: ${colors.white};
    font-size: 0.75rem;
    text-align: center;
`;

const Loading = styled.div`
    ${mixins.flexCol};
    ${mixins.flexCenter};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: radial-gradient(at center, ${colors.darkGray}, ${colors.black});
    opacity: 0.95;
    color: ${colors.white};
    font-size: 0.7rem;
`;

const Overlay = styled.canvas`
    position: absolute;
`;

/* for ad-sense
const AdsContainer = styled.div`
    margin-top: 2rem;
    display: block;
`;
*/

const AdsContainer = styled.div`
    margin-top: 2rem;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        display: block;

        img {
            max-width: 100%;
            height: auto;
            animation: wiggle 0.02s 10, opacity .25s 1;
        }
    }

    @keyframes opacity {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes wiggle {
        0% {
            transform: rotate(0deg);
        }
        25% {
            transform: rotate(2deg);
        }
        50% {
            transform: rotate(0deg);
        }
        75% {
            transform: rotate(-2deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
`;