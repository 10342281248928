// const isDev = window.location.hostname === 'localhost' || window.location.hostname === 'pictofon.local';
const isDev = false;

const localhost = {
    apiKey: "AIzaSyCJPoarztgtvuSf2EvSlVoYyXk4YEokdyU",
    authDomain: "pictofon-test.firebaseapp.com",
    databaseURL: "https://pictofon-test.firebaseio.com",
    projectId: "pictofon-test",
    storageBucket: "pictofon-test.appspot.com",
    messagingSenderId: "845362338482"
};

const prod = {
    apiKey: "AIzaSyDX46Ii1EKNb8Lf8VsfMGmGCORpezpSQDQ",
    authDomain: "pictofon.firebaseapp.com",
    databaseURL: "https://pictofon.firebaseio.com",
    projectId: "pictofon",
    storageBucket: "pictofon.appspot.com",
    messagingSenderId: "616131213160"
};

const FirebaseConfig = isDev ? localhost : prod;

export default FirebaseConfig;