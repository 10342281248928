const blue = '#bdf';
const fog = '#777';
const ash = '#555';
const gray = '#aaa';
const lightGray = '#ccc';
const darkGray = '#444';
const orange = '#ff9052';
const lightOrange = '#ffb359';
const darkOrange = '#d2733e';
const red = '#f00';
const darkRed = '#af1f1f';
const lightRed = '#ff8f8f';
const black = '#000';
const white = '#fff';

const colors = {
    blue,
    fog,
    ash,
    gray,
    lightGray,
    darkGray,
    orange,
    lightOrange,
    darkOrange,
    red,
    darkRed,
    lightRed,
	black,
    white,

    button: {
        active: blue,
        disabled: lightGray,
    }
};

export default colors;