import React, { Component } from 'react';
import styled from 'styled-components';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import mixins from '../styles/mixins';
import colors from '../styles/colors';

const SliderWithTooltip = createSliderWithTooltip(Slider);
const ANGLE_CONTROLLER_SIZE = 50;
const ANGLE_HANDLE_SIZE = 4;

export default class Effects extends Component {
    static defaultProps = {
        onChange: () => Promise.resolve(),
        effects: {
            brightness: {
                changed: false,
                value: 0,
            },

            contrast: {
                changed: false,
                value: 0,
            },

            hue: {
                changed: false,
                value: 0,
            },

            saturation: {
                changed: false,
                value: 0,
            },

            vibrance: {
                changed: false,
                value: 0,
            },

            denoise: {
                changed: false,
                value: 0,
            },

            noise: {
                changed: false,
                value: 0,
            },

            sepia: {
                changed: false,
                value: 0,
            },

            duplicate: {
                changed: false,
                value: {
                    count: 0,
                    angle: 7 * Math.PI / 4,
                    radius: 0.5,
                }
            }
        }
    };

    state = { effects: this.props.effects };

    render() {
        const { effects } = this.state;
        const { brightness, contrast, hue, saturation, vibrance, denoise, noise, sepia, duplicate } = effects;
        const cx = ANGLE_CONTROLLER_SIZE / 2;
        const cy = ANGLE_CONTROLLER_SIZE / 2;
        const angleHandlePosition = {
            left: cx - ANGLE_HANDLE_SIZE / 2 + (duplicate.value.radius * ANGLE_CONTROLLER_SIZE / 2) * Math.cos(duplicate.value.angle),
            top: cy - ANGLE_HANDLE_SIZE / 2 + (duplicate.value.radius * ANGLE_CONTROLLER_SIZE / 2) * Math.sin(duplicate.value.angle),
        }
        return (
            <EffectsWrapper
                onMouseUp={ this.handleAngleInnerMouseUp }
            >
                <EffectWrapper>
                    <Title>Brightness: { brightness.changed ? brightness.value : <Unchanged>unchanged</Unchanged> }</Title>
                    <SliderWithTooltip
                        tipProps={{
                            placement: 'bottom',
                        }}
                        min={ -1 }
                        max={ 1 }
                        step={ 0.01 }
                        value={ brightness.value }
                        onChange={ (value) => this.handleSimpleEffectChange('brightness', value) }
                    />
                </EffectWrapper>

                <EffectWrapper>
                    <Title>Contrast: { contrast.changed ? contrast.value : <Unchanged>unchanged</Unchanged> }</Title>
                    <SliderWithTooltip
                        tipProps={{
                            placement: 'bottom',
                        }}
                        min={ -1 }
                        max={ 1 }
                        step={ 0.01 }
                        value={ contrast.value }
                        onChange={ (value) => this.handleSimpleEffectChange('contrast', value) }
                    />
                </EffectWrapper>

                <EffectWrapper>
                    <Title>Hue: { hue.changed ? hue.value : <Unchanged>unchanged</Unchanged> }</Title>
                    <SliderWithTooltip
                        tipProps={{
                            placement: 'bottom',
                        }}
                        min={ -1 }
                        max={ 1 }
                        step={ 0.01 }
                        value={ hue.value }
                        onChange={ (value) => this.handleSimpleEffectChange('hue', value) }
                    />
                </EffectWrapper>

                <EffectWrapper>
                    <Title>Saturation: { saturation.changed ? saturation.value : <Unchanged>unchanged</Unchanged> }</Title>
                    <SliderWithTooltip
                        tipProps={{
                            placement: 'bottom',
                        }}
                        min={ -1 }
                        max={ 1 }
                        step={ 0.01 }
                        value={ saturation.value }
                        onChange={ (value) => this.handleSimpleEffectChange('saturation', value) }
                    />
                </EffectWrapper>

                <EffectWrapper>
                    <Title>Vibrance: { vibrance.changed ? vibrance.value : <Unchanged>unchanged</Unchanged> }</Title>
                    <SliderWithTooltip
                        tipProps={{
                            placement: 'bottom',
                        }}
                        min={ -1 }
                        max={ 1 }
                        step={ 0.01 }
                        value={ vibrance.value }
                        onChange={ (value) => this.handleSimpleEffectChange('vibrance', value) }
                    />
                </EffectWrapper>

                <EffectWrapper>
                    <Title>Denoise: { denoise.changed ? denoise.value : <Unchanged>unchanged</Unchanged> }</Title>
                    <SliderWithTooltip
                        tipProps={{
                            placement: 'bottom',
                        }}
                        min={ 0 }
                        max={ 50 }
                        step={ 1 }
                        value={ denoise.value }
                        onChange={ (value) => this.handleSimpleEffectChange('denoise', value) }
                    />
                </EffectWrapper>

                <EffectWrapper>
                    <Title>Noise: { noise.changed ? noise.value : <Unchanged>unchanged</Unchanged> }</Title>
                    <SliderWithTooltip
                        tipProps={{
                            placement: 'bottom',
                        }}
                        min={ 0 }
                        max={ 1 }
                        step={ 0.01 }
                        value={ noise.value }
                        onChange={ (value) => this.handleSimpleEffectChange('noise', value) }
                    />
                </EffectWrapper>

                {
                    /*
                    <EffectWrapper>
                        <Title>Sepia: { sepia.changed ? sepia.value : 'unchanged' }</Title>
                        <SliderWithTooltip
                            tipProps={{
                                placement: 'bottom',
                            }}
                            min={ 0 }
                            max={ 1 }
                            step={ 0.01 }
                            value={ sepia.value }
                            onChange={ (value) => this.handleSimpleEffectChange('sepia', value) }
                        />
                    </EffectWrapper>
                    <EffectWrapper>
                        <Title>Image duplicates: { duplicate.value.count === 0 ? 'no' : duplicate.value.count }</Title>
                        <DuplicateWrapper>
                            <DuplicateLeft>
                                <SliderWithTooltip
                                    tipProps={{
                                        placement: 'bottom',
                                    }}
                                    min={ 0 }
                                    max={ 3 }
                                    step={ 1 }
                                    value={ duplicate.value.count }
                                    onChange={ this.handleDuplicateCountChange }
                                />
                            </DuplicateLeft>
                            <DuplicateRight>
                                <AngleWrapper>
                                    <AngleHint>
                                        { Math.round(duplicate.value.angle * 180 / Math.PI) }
                                    </AngleHint>
                                    <AngleInner
                                        ref={ angleInner => this.angleInner = angleInner }
                                        onMouseUp={ this.handleAngleInnerMouseUp }
                                        onMouseMove={ this.handleAngleInnerMouseMove }
                                        onMouseDown={ this.handleAngleInnerMouseDown }
                                        onWheel={ this.handleAngleInnerWheel }
                                    >
                                        <AngleHandle
                                            style={{
                                                left: angleHandlePosition.left,
                                                top: angleHandlePosition.top,
                                            }}
                                        />
                                    </AngleInner>
                                </AngleWrapper>
                            </DuplicateRight>
                        </DuplicateWrapper>
                    </EffectWrapper>
                    */
                }
            </EffectsWrapper>
        );
    }

    handleSimpleEffectChange = (name, value) => {
        this.setState({
            effects: {
                ...this.state.effects,
                [name]: {
                    changed: value !== 0,
                    value,
                }
            },
        }, () => this.props.onChange(this.state.effects));
    }

    handleDuplicateCountChange = (value) => {
        const { effects: { duplicate } } = this.state;
        this.setState({
            effects: {
                ...this.state.effects,
                duplicate: {
                    changed: value !== duplicate.value.count,
                    value: {
                        ...duplicate.value,
                        count: value,
                    }
                }
            }
        }, () => this.props.onChange(this.state.effects));
    }

    moveHandle(x, y) {
        const cx = ANGLE_CONTROLLER_SIZE / 2;
        const cy = ANGLE_CONTROLLER_SIZE / 2;
        const { effects: { duplicate } } = this.state;
        const atan2 = Math.atan2(y - cy, x - cx);
        this.setState({
            effects: {
                ...this.state.effects,
                duplicate: {
                    ...duplicate,
                    value: {
                        ...duplicate.value,
                        radius: Math.sqrt(Math.pow(x - cx, 2) + Math.pow(y - cy, 2)) / ANGLE_CONTROLLER_SIZE * 2,
                        angle: atan2 > 0 ? atan2 : 2 * Math.PI + atan2,
                    }
                }
            }
        });
    }

    handleAngleInnerMouseDown = (event) => {
        this.isMouseDown = true;
        if (event.nativeEvent.target === this.angleInner) {
            this.moveHandle(event.nativeEvent.layerX, event.nativeEvent.layerY);
        }
    }

    handleAngleInnerMouseUp = (event) => {
        event.nativeEvent.stopPropagation();
        this.isMouseDown = false;
        this.props.onChange(this.state.effects);
    }

    handleAngleInnerMouseMove = (event) => {
        if (this.isMouseDown && event.nativeEvent.target === this.angleInner) {
            this.moveHandle(event.nativeEvent.layerX, event.nativeEvent.layerY);
        }
    }

    handleAngleInnerWheel = (event) => {
        const { effects: { duplicate } } = this.state;
        event.nativeEvent.stopPropagation();
        event.nativeEvent.preventDefault();
        this.setState({
            effects: {
                ...this.state.effects,
                duplicate: {
                    ...duplicate,
                    value: {
                        ...duplicate.value,
                        angle: (event.nativeEvent.deltaY < 0 ? (duplicate.value.angle + Math.PI / 180) : (duplicate.value.angle - Math.PI / 180) + 2 * Math.PI) % (2 * Math.PI),
                    }
                }
            }
        }, () => this.props.onChange(this.state.effects));
    }
}

const EffectsWrapper = styled.div`
    padding: 2rem 1rem 3rem 1rem;
    overflow-y: auto;
    height: inherit;
`;

const EffectWrapper = styled.div`
    padding-bottom: 1rem;

    .rc-slider-track {
        background-color: ${colors.orange};
    }

    .rc-slider-handle {
        background-color: ${colors.lightOrange};
        border-color: ${colors.darkOrange};
    }
`;

const Title = styled.div`
    padding-bottom: 0.15rem;
    color: ${colors.darkGray};
`;

const Unchanged = styled.span`
    color: ${colors.gray};
`;

const DuplicateWrapper = styled.div`
    ${mixins.flexRow};
    ${mixins.flexCenter};
`;

const DuplicateLeft = styled.div`
    flex: 1 1 auto;
    max-width: 10rem;
`;

const DuplicateRight = styled.div`
    flex: 1 0 auto;
    padding-left: 1rem;
`;

const AngleWrapper = styled.div`
    ${mixins.flexRow};
    ${mixins.flexCenter};
    width: ${ANGLE_CONTROLLER_SIZE}px;
    height: ${ANGLE_CONTROLLER_SIZE}px;
    position: relative;
`;

const AngleInner = styled.div`
    ${mixins.flexRow};
    ${mixins.flexCenter};
    width: ${ANGLE_CONTROLLER_SIZE}px;
    height: ${ANGLE_CONTROLLER_SIZE}px;
    border: 1px solid ${colors.gray};
    border-radius: 50%;
    position: relative;

    &:hover {
        cursor: pointer;
    }
`;

const AngleHandle = styled.div`
    width: ${ANGLE_HANDLE_SIZE}px;
    height: ${ANGLE_HANDLE_SIZE}px;
    border-radius: 50%;
    position: absolute;
    background-color: ${colors.red};
`;

const AngleHint = styled.div`
    font-size: 0.75rem;
    color: ${colors.gray};
    position: absolute;
`;