import { extendObservable } from 'mobx';
import DB from '../services/DB';

class Auth {
    constructor() {
        extendObservable(this, {
            user: null,
            isAuthenticated: false,
            isAdmin: false,
        });
        DB.auth.onAuthStateChanged(this.handleUserChanged);
    }

    handleUserChanged = (user) => {
        this.user = user;
        this.isAuthenticated = !!user;
        this.isAdmin = user && user.email === 'support@pictofon.com';
    }
}

export default new Auth();