import React from 'react';
import FA from 'react-fontawesome';

import '../styles/Splitter.css';

const Splitter = () => {
    return (
        <div className="splitter-bar">
            <div className="splitter-handler">
                <FA className="caret-left" name="caret-left"/>
                <span className="divider"/>
                <FA className="caret-right" name="caret-right"/>
            </div>
        </div>
    );
}

export default Splitter;