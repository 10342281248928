import React, { Component } from 'react';
import styled from 'styled-components';

import DB from '../services/DB';
import Button from './Button';

import mixins from '../styles/mixins';
import colors from '../styles/colors';

export default class Login extends Component {
    static defaultProps = {
        onLoginSuccess: () => Promise.resolve(),
        onCancel: () => Promise.resolve(),
    };

    state = {
        email: '',
        password: '',
        errorMessage: '',
    };

    render() {
        const { email, password, errorMessage } = this.state;
        const isValid = email.match(/.+@.+/) && password.length > 0;
        return (
            <Wrapper>
                <Title>Login to Pictofon</Title>
                <LoginOptions>
                    <Email
                        ref={ input => this.email = input }
                        type="text"
                        placeholder="Email"
                        value={ email }
                        onInput={ this.handleEmailChange }
                    />
                    <Password
                        ref={ input => this.password = input }
                        type="password"
                        placeholder="Password"
                        value={ password }
                        onInput={ this.handlePasswordChange }
                    />
                    {
                        errorMessage && <Error>{ errorMessage }</Error>
                    }
                    <LoginActions>
                        <Button
                            disabled={ !isValid }
                            text="Login"
                            type="primary"
                            action={ this.handleLoginClick }
                        />
                        <Button
                            text="Cancel"
                            type="secondary"
                            action={ this.props.onCancel }
                        />
                    </LoginActions>
                </LoginOptions>
            </Wrapper>
        );
    }

    componentDidMount() {
        this.email.focus();
    }

    handleEmailChange = (event) => {
        this.setState({ email: event.target.value });
    }

    handlePasswordChange = (event) => {
        this.setState({ password: event.target.value });
    }

    handleLoginClick = () => {
        const { email, password } = this.state;
        DB.auth.signInWithEmailAndPassword(email, password).then(this.props.onLoginSuccess).catch(error => {
            const { message } = error;
            this.setState({ errorMessage: message });
        });
    }
}

const Wrapper = styled.div`
    ${mixins.overlay};
    ${mixins.flexCol};
    ${mixins.flexCenter};
    background-color: rgba(255, 255, 255, 0.98);
`;

const Title = styled.div`
    padding: 1rem;
    font-weight: bold;
`;

const LoginOptions = styled.div`
    width: 20rem;
    padding: 1rem;
    border: 1px solid ${colors.lightGray};
    background-color: ${colors.white};
    ${mixins.flexCol};
`;

const Email = styled.input`
    flex: 1 0 auto;
    font-size: 1rem;
    height: 2.5rem;
    padding: 0 0.5rem;
    &::placeholder {
        color: ${colors.lightGray};
    }
`;

const Password = styled.input`
    flex: 1 0 auto;
    font-size: 1rem;
    height: 2.5rem;
    padding: 0 0.5rem;
    &::placeholder {
        color: ${colors.lightGray};
    }
    margin-top: 1rem;
`;

const LoginActions = styled.div`
    ${mixins.flexRow};
    margin-top: 1rem;

    button {
        margin-right: 1rem;
    }
`;

const Error = styled.div`
    margin-top: 1rem;
    color: ${colors.red};
`;