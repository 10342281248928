import React, { Component } from 'react';
import styled from 'styled-components';
import FA from 'react-fontawesome';
import mixins from '../styles/mixins';
import colors from '../styles/colors';

export default class Button extends Component {
    static defaultProps = {
        disabled: false,
        text: '',
        hint: '',
        sup: '',
        title: '',
        link: '',
        supStyles: {},
        selected: false,
        type: 'default',
        action: () => Promise.resolve(),
    };

    render() {
        const { disabled, text, children, icon, hint, sup, selected, supStyles, title, link, type } = this.props;
        if (link) {
            return (
                <Link href={ link }>
                    { icon && this.renderIcon() }
                    { text !== '' ? <Text>{ text }</Text> : children }
                </Link>
            );
        } else {
            return (
                <Btn
                    disabled={ disabled }
                    selected={ selected }
                    title={ title !== '' ? title : '' }
                    type={ type }
                    onClick={ this.props.action }
                >
                    <Top>
                        { icon && this.renderIcon() }
                        { text !== '' ? <Text disabled={ disabled } hasIcon={ !!icon }>{ text }</Text> : children }
                    </Top>
                    { hint && <Bottom><Hint>{ hint }</Hint></Bottom> }
                    { sup !== '' && <Sup style={ supStyles }>{ sup }</Sup> }
                </Btn>
            );
        }
    }

    renderIcon() {
        const { icon, iconStyles } = this.props;
        if (Array.isArray(icon)) {
            return (
                icon.map(i => <FA key={ i } name={ i } style={ iconStyles[i] ? iconStyles[i] : {} }/>)
            );
        } else {
            return <FA name={ icon }/>;
        }
    }
}

const Link = styled.a`
    color: ${colors.darkGray};
    padding: 0.3rem 0.5rem;
`;

const Btn = styled.button`
    ${mixins.flexCol};
    ${mixins.flexCenter};
    padding: 0 0.75rem;
    height: 2.5rem;
    border: 1px solid ${colors.fog};
    background: ${props => props.type === 'default' ? 'transparent' : 'initial'};
    ${props => props.type === 'primary' && `border-color: ${colors.blue}`};
    ${props => props.type === 'secondary' && `border-color: ${colors.lightRed}`};
    font-size: 1rem;
    outline: none;
    position: relative;
    color: ${props => props.selected ? colors.darkRed : colors.black};
    &:hover {
        cursor: pointer;
    }
    &:active {
        background: ${colors.button.active};
    }
    &:active[disabled] {
        background: inherit;
    }
    &[disabled] {
        border-color: ${colors.button.disabled};
        color: ${colors.button.disabled};
        cursor: default;
    }
`;

const Top = styled.div`
    ${mixins.flexRow};
    justify-content: center;
    align-items: baseline;
`;

const Bottom = styled.div`
    ${mixins.flexRow};
    ${mixins.flexCenter};
`;

const Text = styled.span`
    padding-left: ${props => props.hasIcon ? '0.4rem' : '0'};
    color: ${props => props.disabled ? colors.lightGray : colors.ash }
`;

const Hint = styled.span`
    font-size: 0.5rem;
`;

const Sup = styled.span`
    position: absolute;
    top: 0.1rem;
    right: -0.1rem;
    font-size: 0.55rem;
`