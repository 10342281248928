import FirebaseConfig from '../data/FirebaseConfig';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

class DB {
    constructor() {
        firebase.initializeApp(FirebaseConfig);
        this.database = firebase.database();
        this.auth = firebase.auth();
    }
}

export default new DB();