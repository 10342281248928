import React, { Component } from 'react';
import styled from 'styled-components';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import Spinner from 'react-spinkit';
import Button from './Button';

import mixins from '../styles/mixins';
import colors from '../styles/colors';

const SliderWithTooltip = createSliderWithTooltip(Slider);

const maxWebpageWidth = 2560;
const maxWebpageHeight = 1500;

const heightMarks = {
    600: '600px',
    768: '768',
    800: '800',
    1024: '1024',
    1080: '1080',
    1200: '1200',
    1440: '1440px',
    [maxWebpageHeight]: 'Max',
}

const widthMarks = {
    800: '800px',
    1024: '1024',
    1280: '1280',
    1366: '1366',
    1920: '1920',
    [maxWebpageWidth]: '2560px',
};

export default class WebpageTaker extends Component {
    static defaultProps = {
        onComplete: () => Promise.resolve(),
        onCancel: () => Promise.resolve(),
    };

    state = {
        webpageURL: '',
        promptURL: false,
        sliderWidth: 800,
        sliderHeight: 600,
        loadingWebpage: false,
    };

    render() {
        const { sliderWidth, sliderHeight, webpageURL, loadingWebpage } = this.state;
        let webpageFrameWidth = 0;
        let webpageFrameHeight = 0;
        if (this.webpageFrameContainer) {
            const containerWidth = this.webpageFrameContainer.clientWidth / 1.2;
            const containerHeight = this.webpageFrameContainer.clientHeight / 1.2;
            if (sliderWidth / containerWidth > sliderHeight / containerHeight) {
                webpageFrameWidth = containerWidth;
                webpageFrameHeight = sliderHeight / sliderWidth * containerWidth;
            } else {
                webpageFrameHeight = containerHeight;
                webpageFrameWidth = sliderWidth / sliderHeight * containerHeight;
            }
        } else {
            setTimeout(() => this.forceUpdate(), 250);
        }

        return (
            <PromptWrapper>
                <URLWrapper>
                    <URL
                        value={ webpageURL }
                        onChange={ this.handleURLChange }
                    />
                    <Button
                        text="Fetch"
                        disabled={ !webpageURL.match(/.+\..+/) }
                        action={ this.handleWebpageFetch }
                    />
                    <Button
                        text="Cancel"
                        action={ this.handleWebpageClose }
                    />
                </URLWrapper>
                <SizePickerWrapper>
                    <PickerLeft>
                        <SliderWithTooltip
                            tipProps={{
                                placement: 'left',
                            }}
                            tipFormatter={ value => value === 1500 ? 'Max' : value }
                            vertical
                            reverse
                            min={ 600 }
                            max={ 1500 }
                            value={ sliderHeight }
                            marks={ heightMarks }
                            onChange={ this.handleSliderChangeHeight }
                            onBeforeChange={ this.handleSliderBeforeChangeHeight }
                        />
                    </PickerLeft>
                    <PickerRight>
                        <PickerTop>
                            <SliderWithTooltip
                                tipProps={{
                                    placement: 'top',
                                }}
                                min={ 800 }
                                max={ 2560 }
                                value={ sliderWidth }
                                marks={ widthMarks }
                                onChange={ this.handleSliderChangeWidth }
                            />
                        </PickerTop>
                        <PickerBottom ref={ element => this.webpageFrameContainer = element }>
                            <WebpageFrame
                                style={{
                                    width: `${webpageFrameWidth}px`,
                                    height: `${webpageFrameHeight}px`,
                                    borderBottomStyle: sliderHeight === maxWebpageHeight ? 'dashed' : 'solid',
                                }}
                            >
                                <WebpageFrameHint>{ `${sliderWidth} x ${sliderHeight === maxWebpageHeight ? 'Max' : sliderHeight}` }</WebpageFrameHint>
                            </WebpageFrame>
                        </PickerBottom>
                    </PickerRight>
                </SizePickerWrapper>
                {
                    loadingWebpage &&
                    <Loading>
                        <Spinner name="ball-scale-multiple"/>
                        <Text>Loading</Text>
                    </Loading>
                }
            </PromptWrapper>
        );
    }

    handleURLChange = (event) => {
        this.setState({ webpageURL: event.target.value });
    }

    handleSliderChangeHeight = (value) => {
        let closestMark = null;
        let closestDiff = null; 
        Object.keys(heightMarks).forEach(mark => {
            const markValue = parseInt(mark, 10);
            if (closestMark === null) {
                closestMark = mark;
                closestDiff = Math.abs(markValue - value);
            } else {
                const diff = Math.abs(markValue - value);
                if (diff < closestDiff) {
                    closestMark = mark;
                    closestDiff = diff;
                }
            }
        });
        this.setState({
            sliderHeight: closestDiff < 20 ? parseInt(closestMark, 10) : value,
        });
    }

    handleSliderChangeWidth = (value) => {
        let closestMark = null;
        let closestDiff = null; 
        Object.keys(widthMarks).forEach(mark => {
            const markValue = parseInt(mark, 10);
            if (closestMark === null) {
                closestMark = mark;
                closestDiff = Math.abs(markValue - value);
            } else {
                const diff = Math.abs(markValue - value);
                if (diff < closestDiff) {
                    closestMark = mark;
                    closestDiff = diff;
                }
            }
        });
        this.setState({
            sliderWidth: closestDiff < 50 ? parseInt(closestMark, 10) : value,
        });
    }

    handleWebpageFetch = () => {
        let { webpageURL, sliderWidth, sliderHeight } = this.state;
        const resolution = `${sliderWidth}x${sliderHeight}`;
        if (webpageURL.indexOf('https://') !== 0 && webpageURL.indexOf('http://') !== 0) {
            webpageURL = 'http://' + webpageURL;
        }

        this.setState({ loadingWebpage: true }, () => {
            fetch(`//pictofon.com/screen?resolution=${resolution}&url=${encodeURIComponent(webpageURL)}`).then(response => {
                return response.text();
            }, error => {
                this.setState({ loadingWebpage: false });
            }).then(imageURL => {
                const image = new Image();
                image.src=imageURL;
                image.onload = () => {
                    this.setState({ loadingWebpage: false, promptURL: false });
                    if (sliderHeight !== maxWebpageHeight) {
                        const canvas = document.createElement('canvas');
                        canvas.width = sliderWidth;
                        canvas.height = sliderHeight;
                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(image, 0, 0);
                        const finalImage = new Image();
                        finalImage.src = canvas.toDataURL();
                        finalImage.onload = () => {
                            this.props.onComplete({
                                image: finalImage,
                                isGIF: false,
                                gifFrames: [],
                            });
                        }
                    } else {
                        this.props.onComplete({ image });
                    }
                }
            });
        });
    }

    handleWebpageClose = () => {
        this.props.onCancel();
    }
}

const PromptWrapper = styled.div`
    ${mixins.flexCol};
    height: 100%;
    flex: 1 0 auto;
`;

const URLWrapper = styled.div`
    ${mixins.flexRow};
    ${mixins.flexCenter};
    flex: 0 0 auto;
    button {
        margin: 0.25rem;
    }
`;

const URL = styled.input`
    flex: 1 0 auto;
    margin: 0.25rem 0.25rem 0.25rem 0.75rem;
    height: 2.2rem;
    font-size: 1.5rem;
    padding: 0 0.25rem;
`;

const SizePickerWrapper = styled.div`
    ${mixins.flexRow};
    flex: 1 0 auto;
`;

const PickerLeft = styled.div`
    ${mixins.flexRow};
    ${mixins.flexCenter};
    flex: 0 0 auto;
    min-width: 5.5rem;
    padding: 5rem 0 1rem 0;
`;

const PickerRight = styled.div`
    ${mixins.flexCol};
    flex: 1 0 auto;
`;

const PickerTop = styled.div`
    ${mixins.flexCol};
    ${mixins.flexCenter};
    flex: 0 0 auto;
    height: 5rem;
    padding: 0 2rem 0 3rem;
`;

const PickerBottom = styled.div`
    ${mixins.flexRow};
    ${mixins.flexCenter};
    flex 1 0 auto;
`;

const WebpageFrame = styled.div`
    ${mixins.flexRow};
    ${mixins.flexCenter};
    border: 2px solid ${colors.gray};
`;

const WebpageFrameHint = styled.div`
`;

const Loading = styled.div`
    ${mixins.flexRow};
    ${mixins.flexCenter};
    opacity: 0.95;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: radial-gradient(at center, ${colors.darkGray}, ${colors.black});
    color: ${colors.lightGray};

    .sk-spinner {
        position: absolute;
        color: ${colors.white};
        opacity: 0.85;
    }

    .sk-fade-in {
        animation: unset;
        color: black;
    }
`;

const Text = styled.span`
    font-size: 1rem;
    z-index: 2;
`;