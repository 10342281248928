import DB from './DB';

let deviceTypes = null;
let devices = {};

const API = {
    getDevices: () => {
        return new Promise((resolve, reject) => {
            DB.database.ref('/devices').once('value').then(snapshot => {
                devices = snapshot.val();
                resolve(snapshot.val());
            }, reject);
        });
    },

    getDeviceTypes: () => {
        return new Promise((resolve, reject) => {
            if (!deviceTypes) {
                DB.database.ref('/deviceTypes').once('value').then(snapshot => {
                    deviceTypes = snapshot.val();
                    resolve(snapshot.val());
                }, reject);
            } else {
                resolve(deviceTypes);
            }
        });
    },

    getLastDeviceIndex: (type) => {
        const sorted = Object.keys(devices).filter(key => devices[key].type === type).sort(function(a, b) {
            const aNumber = parseInt(a.substr(a.indexOf('_') + 1), 10);
            const bNumber = parseInt(b.substr(b.indexOf('_') + 1), 10);
            return aNumber > bNumber ? 1 : aNumber === bNumber ? 0 : -1;
        });
        const m = sorted[sorted.length - 1].match(/^.+?(\d+)$/);
        return parseInt(m[1], 10);
    },

    addDevice: (id, data) => {
        return DB.database.ref(`/devices/${id}`).set(data);
    }
}

export default API;