import React from 'react';
import styled from 'styled-components';
import mixins from '../styles/mixins';

const Panel = (props) => {
    return (
        <Wrapper>
            { props.children }
        </Wrapper>
    );
}

const Wrapper = styled.div`
	${mixins.flexCol};
    height: ${window.innerHeight}px;
    flex: 1 1 auto;
    overflow: hidden;
`;

export default Panel;