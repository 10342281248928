import fx from '../lib/glfx';

let isGLFX = false;

try {
    let canvas = fx.canvas();
    isGLFX = true;
    if (canvas) {
        console.log('GLFX enabled');
    }
} catch (e) {
    console.log('GLFX disabled');
}

export function getPerspectiveImage(idx, image, device, width, height) {
    let canvas;
    let ctx;
    let perspective = device.perspective;
    if (Array.isArray(perspective)) {
        perspective = device.perspective[idx];
    }
    if (perspective) {
        if (isGLFX) {
            canvas = fx.canvas();
            const imgW = image.naturalWidth || image.width;
            const imgH = image.naturalHeight || image.height;
            const projectNorm = [];

            const fromPoints = [0, 0, imgW, 0, 0, imgH, imgW, imgH];

            const toPoints = [
                width * perspective.lt.x / 100, height * perspective.lt.y / 100,
                width * perspective.rt.x / 100, height * perspective.rt.y / 100,
                width * perspective.lb.x / 100, height * perspective.lb.y / 100,
                width * perspective.rb.x / 100, height * perspective.rb.y / 100,
            ];

            for (var i = 0; i < toPoints.length; i += 2) {
                projectNorm.push([
                    fromPoints[i] ? toPoints[i] / fromPoints[i] : toPoints[i] / imgW,
                    fromPoints[i + 1] ? toPoints[i + 1] / fromPoints[i + 1] : toPoints[i + 1] / imgH
                ]);
            }

            let top = 0;
            let left = 0;
            let right = imgW;
            let bottom = imgH;
            const project = projectNorm.map(p => [p[0] * imgW, p[1] * imgH]);

            project.forEach(p => {
                top = Math.min(p[1], top);
                left = Math.min(p[0], left);
                bottom = Math.max(p[1], bottom);
                right = Math.max(p[0], right);
            });

            const texture = document.createElement('canvas');
            ctx = texture.getContext('2d');
            texture.width = Math.ceil(right - left);
            texture.height = Math.ceil(bottom - top);

            ctx.drawImage(image, 0, 0);

            const after = [];
            project.forEach(p => after.push(...p));

            after.forEach((p, i) => {
                if (i % 2) {
                    fromPoints[i] += -top;
                    after[i] += -top;
                } else {
                    fromPoints[i] += -left;
                    after[i] += -left;
                }
            });

            const glfxTexture = canvas.texture(texture);
            canvas.draw(glfxTexture).perspective(fromPoints, after).update();
        } else {
            /*
            canvas = document.createElement('canvas');
            ctx = canvas.getContext('2d');
            toPoints = [
                [width * perspective.lt.x / 100, height * perspective.lt.y / 100],
                [width * perspective.rt.x / 100, height * perspective.rt.y / 100],
                [width * perspective.rb.x / 100, height * perspective.rb.y / 100],
                [width * perspective.lb.x / 100, height * perspective.lb.y / 100],
            ];
            canvas.width = width;
            canvas.height = height;
            var op = new html5jp.perspective(ctx, imgObj);
            op.draw(toPoints);
            */
        }
    } else {
        canvas = document.createElement('canvas');
        const x = device.left * width / 100;
        const y = device.top * height / 100;
        const w = width * device.width / 100;
        const h = height * device.height / 100;
        canvas.width = width * device.width / 100 + w;
        canvas.height = height * device.height / 100 + h;
        ctx = canvas.getContext('2d');
        ctx.drawImage(image, x, y, w, h);
    }
    return canvas;
}

export function dataURIToArrayBuffer(dataUri) {
    const binary_string = atob(dataUri.split(',')[1]);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

const gifCanvas = {};
const gifTempCanvas = {};
export function getGIFFrameImage(key, frameImageData, frame, realFrameX, realFrameY, realFrameW, realFrameH) {
    const dims = frame.dims;
    if (!gifCanvas[key] || !gifTempCanvas[key]) {
        gifCanvas[key] = document.createElement('canvas');
        gifTempCanvas[key] = document.createElement('canvas');
    }
    const ctx = gifCanvas[key].getContext('2d');
    const tempCtx = gifTempCanvas[key].getContext('2d');

    if (!frameImageData) {
        gifCanvas[key].width = dims.width;
        gifCanvas[key].height = dims.height;
    }

    if (!frameImageData || dims.width !== frameImageData.width || dims.height !== frameImageData.height) {
        gifTempCanvas[key].width = dims.width;
        gifTempCanvas[key].height = dims.height;
        frameImageData = tempCtx.createImageData(dims.width, dims.height);
    }

    // set the patch data as an override
    frameImageData.data.set(frame.patch);

    // draw the patch back over the canvas
    tempCtx.putImageData(frameImageData, 0, 0);

    ctx.drawImage(gifTempCanvas[key], dims.left, dims.top);

    return {
        frameData: ctx.getImageData(realFrameX, realFrameY, realFrameW, realFrameH),
        frameImageData,
    }
}

export function applyEffects(effects, sourceCanvas, outputCanvas, frame) {
    if (isGLFX && Array.isArray(effects) && effects.length > 0) {
        let noEffects = true;
        const canvas = fx.canvas();
        let glfxTexture = canvas.texture(sourceCanvas);
        const outputCtx = outputCanvas.getContext('2d');
        outputCtx.clearRect(0, 0, outputCanvas.width, outputCanvas.height);

        const effectsObject = {};

        effects.forEach(effect => {
            effectsObject[effect.name] = {
                value: effect.value,
            };
        });

        if (effectsObject.brightness || effectsObject.contrast) {
            canvas.draw(glfxTexture).brightnessContrast(
                effectsObject.brightness && effectsObject.brightness.value || 0,
                effectsObject.contrast && effectsObject.contrast.value || 0
            ).update();
            glfxTexture = canvas.texture(canvas);
            noEffects = false;
        }

        if (effectsObject.hue || effectsObject.saturation) {
            canvas.draw(glfxTexture).hueSaturation(
                effectsObject.hue && effectsObject.hue.value || 0,
                effectsObject.saturation && effectsObject.saturation.value || 0
            ).update();
            glfxTexture = canvas.texture(canvas);
            noEffects = false;
        }

        if (effectsObject.vibrance) {
            canvas.draw(glfxTexture).vibrance(effectsObject.vibrance.value).update();
            glfxTexture = canvas.texture(canvas);
            noEffects = false;
        }

        if (effectsObject.denoise) {
            canvas.draw(glfxTexture).denoise(50 - effectsObject.denoise.value).update();
            glfxTexture = canvas.texture(canvas);
            noEffects = false;
        }

        if (effectsObject.noise) {
            canvas.draw(glfxTexture).noise(effectsObject.noise.value).update();
            glfxTexture = canvas.texture(canvas);
            noEffects = false;
        }

        if (effectsObject.sepia) {
            canvas.draw(glfxTexture).sepia(effectsObject.sepia.value).update();
            glfxTexture = canvas.texture(canvas);
            noEffects = false;
        }

        if (noEffects) {
            canvas.draw(glfxTexture).update();
        }

        outputCtx.drawImage(canvas, frame.x, frame.y, frame.w, frame.h);
    }
}

export function effectsChanged(effects, newEffects) {
    let changed = effects.length !== newEffects.length;
    for (let i = 0; i < effects.length && !changed; i++) {
        let found = false;
        for (let j = 0; j < newEffects.length && !changed && !found; j++) {
            if (effects[i].name === newEffects[j].name) {
                changed = effects[i].value !== newEffects[j].value;
                found = true;
            }
        }
        if (!found) {
            changed = true;
        }
    }
    return changed;
}