import { css } from 'styled-components';

const flexRow = css`
    display: flex;
    flex-direction: row;
`;

const flexCol = css`
    display: flex;
    flex-direction: column;
`;

const flexCenter = css`
    justify-content: center;
    align-items: center;
`;

const overlay = css`
	position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
`;

const mixins = {
    flexRow,
    flexCol,
    flexCenter,
    overlay,
};

export default mixins;