import React, { Component } from 'react';
import LazyLoad from 'react-lazy-load';
import Dropzone from 'react-dropzone'
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import Button from './Button';
import ImageSettings from './ImageSettings';
import Effects from './Effects';
import mixins from '../styles/mixins';
import colors from '../styles/colors';
import DeviceService from '../services/Device';

import '../styles/Mockups.css';

const HANDLE_SIZE = 15;
const MAGNIFIER_SIZE = 200;
let MAGNIFIED_AREA_SIZE = 10;

const Mockups = inject('stores')(observer(class Mockups extends Component {
    static defaultProps = {
        enableEffects: false,
        onEffectsChange: () => Promise.resolve(),
        onMockSelect: () => Promise.resolve(),
        onSaveStart: () => Promise.resolve(),
        onSaveComplete: () => Promise.resolve(),
    };

    state = {
        showEffectsSettings: false,
        showImageSettings: false,
        latest: [],
        images: [],
        selectedImage: {},
        selectedFilter: null,
        userImages: [],
        count: {
            phone: 0,
            laptop: 0,
            tablet: 0,
            monitor: 0,
            mix: 0,
            frame: 0,
            businessCard: 0,
        },
    };

    render() {
        const { enableEffects } = this.props;
        const { showImageSettings, showEffectsSettings } = this.state;
        return (
            <Wrapper>
                { this.renderFilters() }
                { (!showEffectsSettings || !enableEffects) && this.renderDevices() }
                { showImageSettings && this.renderImageSettings() }
                { !showImageSettings && showEffectsSettings && enableEffects && this.renderEffects() }
            </Wrapper>
        )
    }

    renderEffects() {
        const { effects } = this.state;
        return (
            <Effects
                effects={ effects }
                onChange={ this.handleEffectsChange }/>
        );
    }

    renderFilters() {
        const { enableEffects } = this.props;
        const { count, selectedFilter, showEffectsSettings } = this.state;
        return (
            <Filters>
                <EffectsButtonWrapper>
                    <Button
                        icon="magic"
                        title="Apply effects"
                        action={ this.toggleEffectsClick }
                        disabled={ !enableEffects }
                    />
                </EffectsButtonWrapper>
                <Button
                    icon="mobile"
                    title="Show phones only"
                    sup={ count.phone }
                    supStyles={{ right: '-0.2rem' }}
                    selected={ selectedFilter === 'phone' }
                    disabled={ showEffectsSettings && enableEffects }
                    action={ () => this.handleFilterClicked('phone') }
                />
                <Button
                    icon="laptop"
                    title="Show laptops only"
                    sup={ count.laptop }
                    supStyles={{ right: '0.2rem' }}
                    selected={ selectedFilter === 'laptop' }
                    disabled={ showEffectsSettings && enableEffects }
                    action={ () => this.handleFilterClicked('laptop') }
                />
                <Button
                    icon="tablet"
                    title="Show tablets only"
                    sup={ count.tablet }
                    supStyles={{ right: '-0.25rem' }}
                    selected={ selectedFilter === 'tablet' }
                    disabled={ showEffectsSettings && enableEffects }
                    action={ () => this.handleFilterClicked('tablet') }
                />
                <Button
                    icon="tv"
                    title="Show monitors only"
                    sup={ count.monitor }
                    supStyles={{ right: '-0.1rem' }}
                    selected={ selectedFilter === 'monitor' }
                    disabled={ showEffectsSettings && enableEffects }
                    action={ () => this.handleFilterClicked('monitor') }
                />
                <Button
                    icon={ ["laptop", "mobile"] }
                    iconStyles={{
                        mobile: {
                            'fontSize': '1.5rem',
                            'marginLeft': '0.25rem',
                        }
                    }}
                    title="Show mixed"
                    sup={ count.mix }
                    supStyles={{ right: '0.25rem' }}
                    selected={ selectedFilter === 'mix' }
                    disabled={ showEffectsSettings && enableEffects }
                    action={ () => this.handleFilterClicked('mix') }
                />
                <Button
                    icon="vcard-o"
                    title="Show business cards"
                    sup={ count.businessCard }
                    supStyles={{ right: '0.15rem' }}
                    selected={ selectedFilter === 'businessCard' }
                    disabled={ showEffectsSettings && enableEffects }
                    action={ () => this.handleFilterClicked('businessCard') }
                />
                <Button
                    icon="picture-o"
                    title="Show other"
                    sup={ count.frame }
                    supStyles={{ right: '-0.2rem' }}
                    selected={ selectedFilter === 'frame' }
                    disabled={ showEffectsSettings && enableEffects }
                    action={ () => this.handleFilterClicked('frame') }
                />
            </Filters>
        )
    }

    renderDevices() {
        const { stores } = this.props;
        const { latest, images, selectedImage, selectedFilter, userImages } = this.state;
        return (
            <MockupImages>
                <Dropzone onDrop={ this.handleFileSelected }>
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps({
                            className: 'dropzone user-image-dropzone',
                        })}>
                            <input {...getInputProps()} />
                            <Empty>Upload Mockup</Empty>
                        </div>
                    )}
                </Dropzone>
                {/*
                <Dropzone
                    ref={ dropzone => this.dropzone = dropzone }
                    className="dropzone user-image-dropzone"
                    accept="image/*"
                    multiple={ false }
                    onDrop={ this.handleFileSelected }
                >
                    <Empty>Upload Mockup</Empty>
                </Dropzone>
                */}
                {
                    userImages.filter(device => device.type === selectedFilter || !selectedFilter).map(image => {
                        return (
                            <LazyLoad key={ image.id }>
                                <MockupImageWrapper
                                    key={ image.id }
                                    isSelected={ selectedImage.id === image.id }
                                    onClick={ () => this.handleMockupSelect(image) }
                                >
                                    <MockupImage src={ image.fullSize }/>
                                    <MockupSettings>
                                    {
                                        stores.auth.isAdmin &&
                                        <Button
                                            icon="cloud-upload"
                                            title="Save to Cloud"
                                            action={ this.handleMockupSaveClick }
                                        />
                                    }
                                        <Button
                                            icon="cog"
                                            title="Mockup Settings"
                                            action={ this.handleMockupSettingsClick }
                                        />
                                    </MockupSettings>
                                </MockupImageWrapper>
                            </LazyLoad>
                        );
                    })
                }
                {
                    latest.filter(device => device.type === selectedFilter || !selectedFilter).map(image => {
                        const thumbPath = `/img/mocks/thumbs/${image.fullSize.replace(/^.+\/(.+)$/, '$1')}`;
                        return (
                            <LazyLoad key={ image.fullSize }>
                                <MockupImageWrapper
                                    key={ image.fullSize }
                                    isSelected={ selectedImage.fullSize === image.fullSize }
                                    onClick={ () => this.handleMockupSelect(image) }
                                >
                                    <MockupImage src={ thumbPath }/>
                                    <New>new</New>
                                    {
                                        stores.auth.isAdmin &&
                                        <MockupSettings>
                                            <Button
                                                icon="cog"
                                                title="Mockup settings"
                                                action={ this.handleMockupSettingsClick }
                                            />
                                        </MockupSettings>
                                    }
                                </MockupImageWrapper>
                            </LazyLoad>
                        );
                    })
                }

                {
                    images.filter(device => device.type === selectedFilter || !selectedFilter).map((image, idx) => {
                        const thumbPath = `../img/mocks/thumbs/${image.fullSize.replace(/^.+\/(.+)$/, '$1')}`;
                        let output = (
                            <MockupImageWrapper
                                key={ image.fullSize }
                                isSelected={ selectedImage.fullSize === image.fullSize }
                                onClick={ () => this.handleMockupSelect(image) }
                            >
                                <MockupImage src={ thumbPath }/>
                                {
                                    stores.auth.isAdmin &&
                                    <MockupSettings>
                                        <Button
                                            icon="cog"
                                            title="Mockup settings"
                                            action={ this.handleMockupSettingsClick }
                                        />
                                    </MockupSettings>
                                }
                            </MockupImageWrapper>
                        );
                        if (idx > 30) {
                            output = <LazyLoad key={ image.fullSize } offsetVertical={ 200 }>{ output }</LazyLoad>
                        }
                        return output;
                    })
                }

                <Social title="Follow Pictofon to get new mockups first">
                    <Button
                        icon="twitter"
                        link="https://twitter.com/pictofon"
                    />
                    <Button
                        icon="facebook"
                        link="https://www.facebook.com/pictofon"
                    />
                    <Button
                        icon="pinterest-p"
                        link="https://www.pinterest.com/pictofon/free-mockups"
                    />
                    <Button
                        icon="vk"
                        link="https://vk.com/pictofon"
                    />
                </Social>
            </MockupImages>
        );
    }

    renderImageSettings() {
        const { selectedImage } = this.state;
        return (
            <ImageSettings
                selectedImage={ selectedImage }
                onImageUpdate={ this.updateSelectedImage }
                onClose={ this.handleMockupSettingsClose }
            />
        );
    }

    componentDidMount() {
        this.refreshDevices();
        const { hash } = document.location;
        if (hash) {
            if (['#phone', '#laptop', '#tablet', '#monitor', '#mix', '#frame'].indexOf(hash) !== -1) {
                this.setState({ selectedFilter: hash.substr(1) });
            } else if (hash === '#business-card') {
                this.setState({ selectedFilter: 'businessCard' });
            }
        }
    }

    refreshDevices() {
        let maxDate = 0;
        const latest = [];
        DeviceService.getDevices().then(devices => {
            const images = [];
            let { count } = this.state;

            Object.keys(devices).forEach(id => {
                count[devices[id].type] += 1;
                if (devices[id].added && devices[id].added > maxDate) {
                    maxDate = devices[id].added;
                }
            });

            Object.keys(devices).sort(function(a, b) {
                const aType = a.substr(0, a.indexOf('_'));
                const aNumber = parseInt(a.substr(a.indexOf('_') + 1), 10);
                const bType = b.substr(0, b.indexOf('_'));
                const bNumber = parseInt(b.substr(b.indexOf('_') + 1), 10);
                if (aType !== bType) {
                    return aType > bType ? 1 : aType === bType ? 0 : -1;
                } else {
                    return aNumber > bNumber ? 1 : aNumber === bNumber ? 0 : -1;
                }
            }).forEach((name, idx) => {
                const device = devices[name];
                if (device.added !== maxDate) {
                    if (device.endOfList) {
                        images.push(device);
                    } else {
                        images.unshift(device);
                    }
                } else {
                    latest.push(device);
                }
            });

            this.setState({
                count,
                latest,
                images,
                selectedImage: latest[0],
            }, () => {
                this.props.onMockSelect(latest[0]);
            });
        });
        /*
        const newDevices = {}
        Object.keys(devices).forEach(key => {
            const newDevice = {...devices[key]};
            if (Array.isArray(newDevice.perspective)) {
                const perspective = {};
                newDevice.perspective.forEach((p, idx) => {
                    perspective[idx] = p;
                });
                newDevice.perspective = perspective;
            }
            if (Array.isArray(newDevice.ratio)) {
                const ratio = {};
                newDevice.ratio.forEach((r, idx) => {
                    ratio[idx] = r;
                });
                newDevice.ratio = ratio;
            }
            newDevices[key] = newDevice;
        });
        console.log(JSON.stringify(newDevices));
        */
    }

    updateSelectedImage = (updatedImage) => {
        const { userImages } = this.state;
        let found = false;
        for (let i = 0; i < userImages.length && !found; i++) {
            if (userImages[i].id === updatedImage.id) {
                found = true;
                userImages[i] = {
                    ...updatedImage,
                    fullSizeToSave: updatedImage.fullSizeToSave,
                    fullSize: userImages[i].fullSize,
                    ratio: Array.isArray(updatedImage.ratio) ? updatedImage.ratio.map(r => eval(r)) : eval(updatedImage.ratio),
                };
            }
        }
        this.setState({
            selectedImage: updatedImage,
            userImages,
        }, () => {
            this.props.onMockSelect(this.state.selectedImage);
        });
    }


    handleMockupSelect = (selectedImage) => {
        this.setState({ selectedImage }, () => {
            this.props.onMockSelect(selectedImage);
        });
    }

    handleMockupSaveClick = () => {
        const { selectedImage, userImages } = this.state;
        const { stores } = this.props;
        let found = false;
        for (let i = 0; i < userImages.length && !found; i++) {
            if (userImages[i].id === selectedImage.id) {
                found = true;
                this.props.onSaveStart();
                const isDev = window.location.hostname === 'localhost' || window.location.hostname === 'pictofon.local';
                const url = isDev ? `http://localhost:8080/?uid=${stores.auth.user.uid}` : `https://pictofon.com/screen?uid=${stores.auth.user.uid}`;
                fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(userImages[i]),
                }).then(response => {
                    return response.text();
                }, error => {
                    this.props.onSaveComplete();
                    return false;
                }).then(result => {
                    console.log('result:', result);
                    console.log(userImages[i]);
                    if (result) {
                        const id = userImages[i].fullSizeToSave.split('.');
                        DeviceService.addDevice(id[0], {
                            added: parseInt(userImages[i].added, 10),
                            credits: userImages[i].credits,
                            fullSize: `/img/mocks/${userImages[i].fullSizeToSave}`,
                            perspective: userImages[i].perspective,
                            ratio: userImages[i].ratio,
                            type: userImages[i].type,
                        }).then(() => {
                            window.location.reload();
                        }, this.props.onSaveComplete);
                    }
                });
            }
        }
    }

    handleMockupSettingsClick = () => {
        this.setState({
            showImageSettings: true,
        });
    }

    handleMockupSettingsClose = (newMockupProps) => {
        const { selectedImage } = this.state;
        const updatedImage = {
            ...selectedImage,
            type: newMockupProps.type,
            ratio: Array.isArray(newMockupProps.ratio) ? newMockupProps.ratio.map(r => eval(r)) : eval(newMockupProps.ratio),
            resolution: newMockupProps.ratio,
            fullSizeToSave: newMockupProps.fullSize,
            credits: {
                title: newMockupProps.creditsTitle,
                link: newMockupProps.creditsLink,
            },
            added: newMockupProps.added,
        }
        this.setState({ showImageSettings: false });
        this.updateSelectedImage(updatedImage);
    }

    handleFilterClicked = (filterName) => {
        const { selectedFilter } = this.state;
        this.setState({
            selectedFilter: filterName !== selectedFilter ? filterName : null,
        });
    }

    handleFileSelected = (files) => {
        const reader = new FileReader();
        reader.onloadstart = () => {
        };
        reader.onabort = () => {
        };
        reader.onerror = () => {
        };
        reader.onload = (event) => {
            DeviceService.getDeviceTypes().then(deviceTypes => {
                const { userImages } = this.state;
                const newImage = {
                    id: Date.now(),
                    fullSize: event.target.result,
                    perspective: {
                        lt: { x: 40, y: 40 },
                        rt: { x: 60, y: 40 },
                        rb: { x: 60, y: 60 },
                        lb: { x: 40, y: 60 },
                    },
                    type: deviceTypes.user,
                };
                this.setState({
                    userImages: [ newImage, ...userImages ],
                    showImageSettings: true,
                    selectedImage: newImage,
                }, this.props.onMockSelect(newImage));
            });
        }
        reader.readAsDataURL(files[0]);
    }    

    handleEffectsClose = () => {
        this.setState({ showEffectsSettings: false });
    }

    toggleEffectsClick = () => {
        const { showEffectsSettings } = this.state;
        this.setState({ showEffectsSettings: !showEffectsSettings });
    }

    handleEffectsChange = (effects) => {
        this.setState({ effects }, () => {
            this.props.onEffectsChange(
                Object.keys(effects).map(key => {
                    return {
                        name: key,
                        changed: effects[key].changed,
                        value: effects[key].value,
                    };
                }).
                filter(effect => effect.changed).map(filter => {
                    return {
                        name: filter.name,
                        value: filter.value,
                    };
                })
            );
        });
    }
}));

export default Mockups;

const Wrapper = styled.div`
    ${mixins.flexCol};
    height: 100%;
    overflow: hidden;
`;

const Filters = styled.div`
    ${mixins.flexRow};
    ${mixins.flexCenter};
    flex-wrap: wrap;
    flex: 1 0 auto;
    width: auto;
    background: ${colors.lightGray};

    .fa-object-group {
        font-size: 1.75rem;
    }

    .fa-vcard-o {
        font-size: 1.75rem;
    }

    button {
        margin: 0 0.75rem;
        border: 0;
        font-size: 2rem;
        &:hover {
            cursor: pointer;
        }
    }

    button[disabled] {
        color: ${colors.gray};
        &:hover {
            cursor: default;
        }
    }
`;

const MockupImages = styled.div`
    ${mixins.flexRow};
    ${mixins.flexCenter};
    flex: 1 1 auto;
    flex-wrap: wrap;
    padding: 0.25rem 0.5rem 0 1.5rem;
    overflow-y: scroll;
    font-size: 1.2rem;
    color: ${colors.darkGray};
    position: relative;
    height: inherit;
`;

const Social = styled.div`
    position: absolute;
    left: 0;
    top: 0.75rem;
    a {
        display: block;
        font-size: 1.5rem;
        padding-bottom: 1rem;

        .fa-facebook {
            padding-top: 0.2rem;
        }

        .fa-pinterest-p {
            font-size: 1.5rem;
            padding-top: 0.3rem;
        }

        .fa-google-plus {
            font-size: 1.2rem;
            padding-top: 0;
        }

        .fa-vk {
            font-size: 1.2rem;
        }
    }

    @media (max-width: 800px) {
        display: none;
    }
`;

const MockupImageWrapper = styled.div`
    position: relative;
    padding: 1rem 1.5rem 0.25rem 1.5rem;
    box-shadow: ${props => props.isSelected ? '0 2px 0 black' : 'none'};
    &:hover {
        cursor: pointer;
    }
    min-width: 100px;
    min-height: 100px;

    button {
        visibility: hidden;
    }

    &:hover button {
        visibility: visible;
    }
`;

const MockupSettings = styled.div`
    position: absolute;
    bottom: 0;
    right: -0.5rem;

    button {
        border: 0;
        font-size: 1rem;
        color: ${colors.black};
    }
`;

const MockupImage = styled.img`
    width: 180px;
`;

const New = styled.span`
    position: absolute;
    top: 0.75rem;
    right: 0;
    color: ${colors.red};
    font-size: 0.7rem;
`;

const Empty = styled.div`
    flex: 0 0 auto;
`;

const EffectsButtonWrapper = styled.div`
    margin-right: 2rem;

    button {
        font-size: 1.5rem;
    }

    button[disabled] {
        color: ${colors.gray};
        &:hover {
            cursor: default;
        }
    }
`;