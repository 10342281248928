import React, { Component } from 'react';
import Panel from './Panel';
import EditArea from './EditArea';
import Mockups from './Mockups';
import styled from 'styled-components';

import mixins from '../styles/mixins';
import colors from '../styles/colors';

export default class EditPanel extends Component {
    static defaultProps = {
        onChange: () => Promise.resolve(),
        onEffectsChange: () => Promise.resolve(),
    };

    state = {
        selectedMock: {},
        selectedFrame: {
            0: {},
        },
        frameCoordinates: {
            0: null,
        },
        gifFrames: {
            0: null,
        },
        effects: [],
        saving: false,
    };

    render() {
        const { selectedMock, selectedFrame, effects, saving } = this.state;
        const enableEffects = !!selectedFrame && !!Object.keys(selectedFrame).map(key => selectedFrame[key]).filter(frame => frame.width > 0 && frame.height > 0).length;
        return (
            <Panel>
                <Top>
                    <EditArea
                        mock={ selectedMock }
                        effects={ effects }
                        onFrameChange={ this.handleFrameChanged }
                    />
                </Top>
                <Bottom>
                    <Mockups
                        enableEffects={ enableEffects }
                        onEffectsChange={ this.handleEffectsChanged }
                        onMockSelect={ this.handleMockSelected }
                        onSaveStart={ this.handleSaveStart }
                        onSaveComplete={ this.handleSaveComplete }
                    />
                </Bottom>
                { saving && this.renderSavingOverlay() }
            </Panel>
        );
    }

    renderSavingOverlay() {
        return (
            <Saving>
                <span>Uploading mockup to server</span>
            </Saving>
        );
    }

    handleSaveStart = () => {
        this.setState({ saving: true });
    }

    handleSaveComplete = () => {
        this.setState({ saving: false });
    }

    handleMockSelected = (mock) => {
        const { gifFrames } = this.state;
        this.setState({
            selectedMock: mock,
            selectedFrame: {
                0: {},
            },
            frameCoordinates: {
                0: null,
            },
            gifFrames,
        }, this.handleChange);
    }

    handleFrameChanged = (idx, frame, frameCoordinates, gifFrames) => {
        this.setState({
            selectedFrame: {
                ...this.state.selectedFrame,
                [idx]: frame ? frame : {},
            },
            frameCoordinates: {
                ...this.state.frameCoordinates,
                [idx]: frameCoordinates ? frameCoordinates : null,
            },
            gifFrames: {
                ...this.state.gifFrames,
                [idx]: gifFrames ? gifFrames : null,
            },
        }, this.handleChange);
    }

    handleChange() {
        const { selectedMock, selectedFrame, frameCoordinates, gifFrames } = this.state;
        this.props.onChange(selectedMock, selectedFrame, frameCoordinates, gifFrames);
    }

    handleEffectsChanged = (effects) => {
        this.setState({ effects });
        this.props.onEffectsChange(effects);
    }
}

const Top = styled.div`
    ${mixins.flexCol};
    user-select: none;
    flex-grow: 1;
    padding: 1rem;
    height: 50%;
`;

const Bottom = styled.div`
    user-select: none;
    flex-grow: 1;
    padding: 0 1rem 0.5rem 1rem;
    height: 50%;
`;

const Saving = styled.div`
    ${mixins.overlay};
    ${mixins.flexRow};
    ${mixins.flexCenter};
    background-image: radial-gradient(at center, ${colors.darkGray}, ${colors.black});
    opacity: 0.95;
    color: ${colors.white};
`;