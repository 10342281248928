import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import Button from './Button';
import DeviceService from '../services/Device';

import mixins from '../styles/mixins';
import colors from '../styles/colors';

const HANDLE_SIZE = 15;
const MAGNIFIER_SIZE = 200;
let MAGNIFIED_AREA_SIZE = 10;

const date = new Date();
const dateYear = date.getFullYear();
const dateMonth = date.getMonth() + 1;
const dateDay = date.getDate();

let formattedDate = dateYear + '';
formattedDate += dateMonth < 10 ? `0${dateMonth}` : dateMonth;
formattedDate += dateDay < 10 ? `0${dateDay}` : dateDay;

function pointDiff(a, b) {
    return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
}

const ImageSettings = inject('stores')(observer(class ImageSettings extends Component {
    static defaultProps = {
        selectedImage: {},
    };

    state = {
        ratio: this.props.selectedImage.type === 'user' ? '1080 / 1920' : (this.props.selectedImage.resolution || this.props.selectedImage.ratio),
        type: this.props.selectedImage.type === 'user' ? 'phone' : this.props.selectedImage.type,
        fullSize: '',
        creditsTitle: this.props.selectedImage.type === 'user' ? '' : this.props.selectedImage.credits.title,
        creditsLink: this.props.selectedImage.type === 'user' ? '' : this.props.selectedImage.credits.link,
        added: `${formattedDate}`,
        isMoving: false,
    };

    render() {
        const { isMoving } = this.state;
        const { selectedImage } = this.props;
        return (
            <Wrapper>
                <UserImageWrapper>
                    <UserImage src={ selectedImage.fullSize }/>
                    <UserImageOverlay
                        key={ selectedImage.id }
                        ref={ this.handleUserImageOverlayReady }
                        onMouseDown={ this.handleUserImageOverlayMouseDown }
                        onMouseMove={ this.handleUserImageOverlayMouseMove }
                        onMouseUp={ this.handleUserImageOverlayMouseUp }
                        onWheel={ this.handleUserImageOverlayMouseWheel }
                    />
                    <UserImageMagnifier
                        isMoving={ isMoving }
                        ref={ magnifier => this.magnifierCanvas = magnifier }
                        width={ MAGNIFIER_SIZE }
                        height={ MAGNIFIER_SIZE }
                        onMouseDown={ this.handleUserImageMagnifierMouseDown }
                        onMouseMove={ this.handleUserImageMagnifierMouseMove }
                        onMouseUp={ this.handleUserImageMagnifierMouseUp }
                        onWheel={ this.handleUserImageOverlayMouseWheel }
                    />
                    <UserImageDone>
                        <UserImageActions>
                            <Button
                                icon="plus"
                                action={ this.handleAddFrame }
                            />
                            <Button
                                icon="check"
                                action={ this.handleMockupSettingsClose }
                            />
                        </UserImageActions>
                        { this.renderImageProps() }
                    </UserImageDone>
                </UserImageWrapper>
            </Wrapper>
        );
    }

    componentDidMount() {
        const { selectedImage } = this.props;
        this.setState({ fullSize: (selectedImage.type === 'user' || selectedImage.fullSize.indexOf('data') === 0) ? this.getFullSizePath() : selectedImage.fullSize });
    }

    componentWillReceiveProps(nextProps) {
        const { selectedImage } = nextProps;
        this.updateFramePosition(selectedImage);
        this.setState({
            fullSize: (selectedImage.type === 'user' || selectedImage.fullSize.indexOf('data') === 0) ? this.getFullSizePath() : selectedImage.fullSize,
            ratio: selectedImage.ratio,
        });
    }

    getFullSizePath() {
        const { type } = this.state;
        return `${type}_${DeviceService.getLastDeviceIndex(type) + 1}.png`;
    }

    renderImageProps() {
        const { selectedImage, stores } = this.props;
        const { ratio, type, creditsTitle, creditsLink, added, fullSize } = this.state;
        let ratios = Array.isArray(ratio) ? ratio : [ratio];
        return (
            <ImageProps>
                {
                    ratios.map((r, idx) => {
                        const handler = this.handleRatioChange.bind(this, idx);
                        return (
                            <PropRow key={ idx }>
                                <Input
                                    type="text"
                                    placeholder="Ratio"
                                    value={ r }
                                    onInput={ handler }
                                />
                            </PropRow>
                        );
                    })
                }
                {
                    stores.auth.isAdmin &&
                    [<PropRow key="type">
                        <span>Device Type: </span>
                        <select
                            value={ type }
                            onChange={ this.handleTypeChange }
                        >
                            <option value="phone">Phone</option>
                            <option value="laptop">Laptop</option>
                            <option value="tablet">Tablet</option>
                            <option value="monitor">Monitor</option>
                            <option value="businessCard">Business Card</option>
                            <option value="frame">Frame</option>
                            <option value="mix">Mix</option>
                        </select>
                    </PropRow>,
                    <PropRow key="fullSize">
                        <Input
                            type="text"
                            placeholder="fullSize path"
                            disabled
                            value={ fullSize }
                        />
                    </PropRow>,
                    <PropRow key="creditsTitle">
                        <Input
                            type="text"
                            placeholder="Credits Title"
                            value={ creditsTitle }
                            onInput={ this.handleCreditsTitleChange }
                        />
                    </PropRow>,
                    <PropRow key="creditsLink">
                        <Input
                            type="text"
                            placeholder="Credits Link"
                            value={ creditsLink }
                            onInput={ this.handleCreditsLinkChange }
                        />
                    </PropRow>,
                    <PropRow key="added">
                        <Input
                            type="text"
                            placeholder="Date added (YYYYMMDD)"
                            value={ added }
                            onInput={ this.handleAddedChange }
                        />
                    </PropRow>]
                }
            </ImageProps>
        );
    }

    handleAddFrame = () => {
        const { selectedImage } = this.props;
        const { ratio } = this.state;
        const updatedImage = { ...selectedImage };
        const ratios = Array.isArray(ratio) ? ratio : [ratio];
        const perspectives = Array.isArray(selectedImage.perspective) ? selectedImage.perspective : [selectedImage.perspective];
        ratios.push('1080 / 1920');
        const offset = (HANDLE_SIZE * 5 * (ratios.length - 1)) / 100;
        perspectives.push({
            lt: { x: 40 + offset, y: 40 + offset },
            rt: { x: 60 + offset, y: 40 + offset },
            rb: { x: 60 + offset, y: 60 + offset },
            lb: { x: 40 + offset, y: 60 + offset },
        });
        updatedImage.ratio = ratios;
        updatedImage.perspective = perspectives;
        this.updateSelectedImage(updatedImage);
    }

    handleRatioChange = (idx, event) => {
        const { ratio } = this.state;
        if (Array.isArray(ratio)) {
            ratio[idx] = event.target.value
            this.setState({ ratio });
        } else {
            this.setState({ ratio: event.target.value });
        }
    }

    handleTypeChange = (event) => {
        const type = event.target.value;
        const { selectedImage } = this.props;
        this.setState({ type }, () => {
            this.setState({ fullSize: (selectedImage.type === 'user' || selectedImage.fullSize.indexOf('data') === 0) ? this.getFullSizePath() : selectedImage.fullSize });
        });
    }

    handleCreditsTitleChange = (event) => {
        this.setState({ creditsTitle: event.target.value });
    }

    handleCreditsLinkChange = (event) => {
        this.setState({ creditsLink: event.target.value });
    }

    handleAddedChange = (event) => {
        this.setState({ added: event.target.value });
    }

    handleMockupSettingsClose = () => {
        const { ratio, type, fullSize, creditsTitle, creditsLink, added } = this.state;
        this.props.onClose({
            ratio,
            type,
            fullSize,
            creditsTitle,
            creditsLink,
            added,
        });
    }

    handleUserImageOverlayReady = (overlay) => {
        this.overlayCanvas = overlay;
        if (this.overlayCanvas) {
            this.updateFramePosition(this.props.selectedImage);
        }
    }

    handleUserImageOverlayMouseDown = (event) => {
        this.mouseIsDown = true;
        this.closestCorner = null;
        this.findClosestCornerAndUpdateFrame(event.nativeEvent.offsetX, event.nativeEvent.offsetY, true);
    }

    handleUserImageMagnifierMouseDown = (event) => {
        this.mouseIsDown = true;
        this.closestCorner = null;
        this.setState({ isMoving: true });
    }

    handleUserImageOverlayMouseMove = (event) => {
        if (this.mouseIsDown && (event.nativeEvent.movementX !== 0 || event.nativeEvent.movementY !== 0)) {
            this.findClosestCornerAndUpdateFrame(event.nativeEvent.offsetX, event.nativeEvent.offsetY, false);
            if (this.closestCorner) {
                this.adjustedCorner = this.closestCorner;
            }
        }
    }

    handleUserImageMagnifierMouseMove = (event) => {
        if (this.mouseIsDown && (event.nativeEvent.movementX !== 0 || event.nativeEvent.movementY !== 0)) {
            this.findClosestCornerAndUpdateFrame(event.nativeEvent.clientX, event.nativeEvent.clientY, false, {
                dx: event.nativeEvent.movementX,
                dy: event.nativeEvent.movementY,
            });
        }
    }

    handleUserImageOverlayMouseUp = () => {
        this.mouseIsDown = false;
    }

    handleUserImageMagnifierMouseUp = () => {
        this.mouseIsDown = false;
        this.setState({ isMoving: false });
    }

    handleUserImageOverlayMouseWheel = (event) => {
        const { selectedImage } = this.props;
        if (this.adjustedCorner) {
            const perspectives = Array.isArray(selectedImage.perspective) ? selectedImage.perspective : [selectedImage.perspective];
            if (event.nativeEvent.deltaY < 0 && MAGNIFIED_AREA_SIZE > 1) {
                MAGNIFIED_AREA_SIZE--;
                this.updateMagnifyingGlass(
                    perspectives[this.adjustedCorner[0]][this.adjustedCorner[1]].x,
                    perspectives[this.adjustedCorner[0]][this.adjustedCorner[1]].y,
                    true
                );
            } else if (event.nativeEvent.deltaY > 0 && MAGNIFIED_AREA_SIZE < MAGNIFIER_SIZE / 10) {
                MAGNIFIED_AREA_SIZE++;
                this.updateMagnifyingGlass(
                    perspectives[this.adjustedCorner[0]][this.adjustedCorner[1]].x,
                    perspectives[this.adjustedCorner[0]][this.adjustedCorner[1]].y,
                    true
                );
            }
        }
    }

    updateFramePosition(selectedImage) {
        if (this.overlayCanvas) {
            const image = new Image();
            image.src = selectedImage.fullSize;
            image.onload = () => {
                this.overlayCanvas.width = image.width;
                this.overlayCanvas.height = image.height;
                const ctx = this.overlayCanvas.getContext('2d');
                const perspectives = Array.isArray(selectedImage.perspective) ? selectedImage.perspective : [selectedImage.perspective];
                perspectives.forEach(perspective => {
                    const lt = { x: perspective.lt.x, y: perspective.lt.y };
                    const rt = { x: perspective.rt.x, y: perspective.rt.y };
                    const lb = { x: perspective.lb.x, y: perspective.lb.y };
                    const rb = { x: perspective.rb.x, y: perspective.rb.y };
                    [lt, rt, lb, rb].forEach(corner => {
                        corner.x *= image.width / 100;
                        corner.y *= image.height / 100;
                    });
                    ctx.strokeStyle = colors.red;
                    ctx.fillStyle = 'rgba(255, 0, 0, 0.05)';
                    ctx.beginPath();
                    ctx.moveTo(lt.x, lt.y);
                    ctx.lineTo(rt.x, rt.y);
                    ctx.lineTo(rb.x, rb.y);
                    ctx.lineTo(lb.x, lb.y);
                    ctx.lineTo(lt.x, lt.y);
                    ctx.closePath();
                    ctx.stroke();
                    ctx.fill();

                    ctx.fillStyle = colors.red;

                    ctx.beginPath();
                    ctx.rect(lt.x - HANDLE_SIZE / 2, lt.y - HANDLE_SIZE / 2, HANDLE_SIZE, HANDLE_SIZE);
                    ctx.closePath();
                    ctx.fill();

                    ctx.beginPath();
                    ctx.rect(rt.x - HANDLE_SIZE / 2, rt.y - HANDLE_SIZE / 2, HANDLE_SIZE, HANDLE_SIZE);
                    ctx.closePath();
                    ctx.fill();

                    ctx.beginPath();
                    ctx.rect(lb.x - HANDLE_SIZE / 2, lb.y - HANDLE_SIZE / 2, HANDLE_SIZE, HANDLE_SIZE);
                    ctx.closePath();
                    ctx.fill();

                    ctx.beginPath();
                    ctx.rect(rb.x - HANDLE_SIZE / 2, rb.y - HANDLE_SIZE / 2, HANDLE_SIZE, HANDLE_SIZE);
                    ctx.closePath();
                    ctx.fill();
                });
            }
        }
    }

    findClosestCornerAndUpdateFrame(x, y, initial, moveProps) {
        if (this.overlayCanvas) {
            const { selectedImage } = this.props;
            const perspectives = Array.isArray(selectedImage.perspective) ? selectedImage.perspective : [selectedImage.perspective];
            if (!moveProps) {
                const point = { x, y };
                let closestDiff = Infinity;

                if (!this.closestCorner) {
                    perspectives.forEach((perspective, idx) => {
                        const lt = { x: perspective.lt.x, y: perspective.lt.y };
                        const rt = { x: perspective.rt.x, y: perspective.rt.y };
                        const lb = { x: perspective.lb.x, y: perspective.lb.y };
                        const rb = { x: perspective.rb.x, y: perspective.rb.y };
                        [lt, rt, lb, rb].forEach(corner => {
                            corner.x *= this.overlayCanvas.clientWidth / 100;
                            corner.y *= this.overlayCanvas.clientHeight / 100;
                        });

                        [[lt, [idx, 'lt']], [rt, [idx, 'rt']], [lb, [idx, 'lb']], [rb, [idx, 'rb']]].forEach(corner => {
                            const diff = pointDiff(corner[0], point);
                            if (diff < closestDiff) {
                                this.closestCorner = corner[1];
                                closestDiff = diff;
                            }
                        });
                    });
                } else {
                    closestDiff = 0;
                }


                if (closestDiff <= HANDLE_SIZE) {
                    if (initial) {
                        this.adjustedCorner = this.closestCorner;
                        this.updateMagnifyingGlass(
                            perspectives[this.adjustedCorner[0]][this.adjustedCorner[1]].x,
                            perspectives[this.adjustedCorner[0]][this.adjustedCorner[1]].y,
                            true
                        );
                    } else {
                        const newX = point.x / this.overlayCanvas.clientWidth * 100;
                        const newY = point.y / this.overlayCanvas.clientHeight * 100;
                        if (this.magnifierCanvas) {
                            this.updateMagnifyingGlass(newX, newY, false);
                        }

                        const updatedImage = { ...selectedImage };
                        if (Array.isArray(selectedImage.perspective)) {
                            selectedImage.perspective[this.closestCorner[0]][this.closestCorner[1]].x = newX;
                            selectedImage.perspective[this.closestCorner[0]][this.closestCorner[1]].y = newY;
                        } else {
                            updatedImage.perspective = {
                                ...selectedImage.perspective,
                                [this.closestCorner[1]]: {
                                    x: newX,
                                    y: newY,
                                }
                            }
                        }
                        this.updateSelectedImage(updatedImage);
                    }
                }
            } else {
                const center = { x: this.overlayCanvas.clientWidth / 2, y: this.overlayCanvas.clientHeight / 2 };
                if (this.adjustedCorner && moveProps) {
                    const dx = moveProps.dx / (MAGNIFIED_AREA_SIZE < 5 ? 5 / MAGNIFIED_AREA_SIZE : 1);
                    const dy = moveProps.dy / (MAGNIFIED_AREA_SIZE < 5 ? 5 / MAGNIFIED_AREA_SIZE : 1);
                    const newX = perspectives[this.adjustedCorner[0]][this.adjustedCorner[1]].x - dx / this.overlayCanvas.width * MAGNIFIED_AREA_SIZE;
                    const newY = perspectives[this.adjustedCorner[0]][this.adjustedCorner[1]].y - dy / this.overlayCanvas.height * MAGNIFIED_AREA_SIZE;
                    const updatedImage = { ...selectedImage }
                    if (Array.isArray(selectedImage.perspective)) {
                        selectedImage.perspective[this.adjustedCorner[0]][this.adjustedCorner[1]].x = newX;
                        selectedImage.perspective[this.adjustedCorner[0]][this.adjustedCorner[1]].y = newY;
                    } else {
                        updatedImage.perspective = {
                            ...selectedImage.perspective,
                            [this.adjustedCorner[1]]: {
                                x: newX,
                                y: newY,
                            }
                        }
                    }
                    this.updateSelectedImage(updatedImage);
                    this.updateMagnifyingGlass(newX, newY, true);
                }
                this.closestCorner = null;
            }
        }
    }

    updateSelectedImage = (updatedImage) => {
        this.props.onImageUpdate(updatedImage);
    }

    updateMagnifyingGlass(newX, newY, crossOpacity) {
        const { selectedImage } = this.props;
        const image = new Image();
        image.src = selectedImage.fullSize;
        image.onload = () => {
            const magnifierCtx = this.magnifierCanvas.getContext('2d');
            magnifierCtx.imageSmoothingEnabled= false;
            magnifierCtx.clearRect(0, 0, MAGNIFIER_SIZE, MAGNIFIER_SIZE);
            const canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0);
            magnifierCtx.drawImage(
                canvas,
                newX / 100 * image.width - MAGNIFIED_AREA_SIZE, newY / 100 * image.height - MAGNIFIED_AREA_SIZE,
                2 * MAGNIFIED_AREA_SIZE, 2 * MAGNIFIED_AREA_SIZE,
                0, 0,
                MAGNIFIER_SIZE, MAGNIFIER_SIZE);

            if (crossOpacity) {
                magnifierCtx.strokeStyle = 'rgba(255, 0, 0, 0.25)';
            } else {
                magnifierCtx.strokeStyle = colors.red;
            }

            magnifierCtx.beginPath();
            magnifierCtx.moveTo(MAGNIFIER_SIZE / 2, MAGNIFIER_SIZE / 2 - 5);
            magnifierCtx.lineTo(MAGNIFIER_SIZE / 2, MAGNIFIER_SIZE / 4);
            magnifierCtx.closePath();
            magnifierCtx.stroke();

            magnifierCtx.beginPath();
            magnifierCtx.moveTo(MAGNIFIER_SIZE / 2, MAGNIFIER_SIZE / 2 + 5);
            magnifierCtx.lineTo(MAGNIFIER_SIZE / 2, MAGNIFIER_SIZE / 2 + MAGNIFIER_SIZE / 4);
            magnifierCtx.closePath();
            magnifierCtx.stroke();

            magnifierCtx.beginPath();
            magnifierCtx.moveTo(MAGNIFIER_SIZE / 2 - 5, MAGNIFIER_SIZE / 2);
            magnifierCtx.lineTo(MAGNIFIER_SIZE / 4, MAGNIFIER_SIZE / 2);
            magnifierCtx.closePath();
            magnifierCtx.stroke();

            magnifierCtx.beginPath();
            magnifierCtx.moveTo(MAGNIFIER_SIZE / 2 + 5, MAGNIFIER_SIZE / 2);
            magnifierCtx.lineTo(MAGNIFIER_SIZE / 2 + MAGNIFIER_SIZE / 4, MAGNIFIER_SIZE / 2);
            magnifierCtx.closePath();
            magnifierCtx.stroke();

            magnifierCtx.beginPath();
            magnifierCtx.moveTo(MAGNIFIER_SIZE / 2 - 1, MAGNIFIER_SIZE / 2);
            magnifierCtx.lineTo(MAGNIFIER_SIZE / 2 + 1, MAGNIFIER_SIZE / 2);
            magnifierCtx.moveTo(MAGNIFIER_SIZE / 2, MAGNIFIER_SIZE / 2 - 1);
            magnifierCtx.lineTo(MAGNIFIER_SIZE / 2, MAGNIFIER_SIZE / 2 + 1);
            magnifierCtx.closePath();
            magnifierCtx.stroke();
        }
    }
}));

export default ImageSettings;

const Wrapper = styled.div`
    ${mixins.flexRow};
    ${mixins.flexCenter};
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: black;
    z-index: 2;
`;

const UserImageWrapper = styled.div`
    ${mixins.flexRow};
    ${mixins.flexCenter};
    flex: 0 0 auto;
    height: ${window.innerHeight}px;
    max-width: 100%;
`;

const UserImage = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

const UserImageMagnifier = styled.canvas`
    position: fixed;
    left: ${window.innerWidth / 2 - 100}px;
    top: ${window.innerHeight / 2 - 100}px;
    width: ${MAGNIFIER_SIZE}px;
    height: ${MAGNIFIER_SIZE}px;
    border-radius: 50%;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.5);
    cursor: ${props => props.isMoving ? 'grabbing' : 'grab'};
`;

const UserImageOverlay = styled.canvas`
    max-width: 100%;
    max-height: 100%;
    position: absolute;
`;

const UserImageDone = styled.div`
    ${mixins.flexCol};
    align-items: flex-end;
    position: fixed;
    top: 0.75rem;
    right: 1.5rem;
`;

const UserImageActions = styled.div`
    ${mixins.flexRow};
    button {
        margin-left: 1rem;
        border: 0;
        font-size: 2rem;
        background-color: ${colors.white};
    }
`;

const ImageProps = styled.div`
    background-color: ${colors.white};
    padding: 1rem;
    margin-top: 0.5rem;
    ${mixins.flexCol};
`;

const PropRow = styled.div`
    padding: 0.5rem 0;
    flex: 1 0 auto;
`;

const Input = styled.input`
    flex: 1 0 auto;
    font-size: 1rem;
    height: 2.5rem;
    padding: 0 0.5rem;
    &::placeholder {
        color: ${colors.lightGray};
    }
`;